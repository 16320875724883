<template>
  <div class="app-container">
    <Sidebar />
    <div class="content-container">
      <StudentHeader />
      <div class="content">
        <h2>Administrative Tasks</h2>

        <!-- Attendance Management Section -->
        <div class="admin-section">
          <h3>Attendance Management</h3>
          <p>Record and manage student attendance.</p>
          <button @click="takeAttendance" class="primary-button">Take Attendance</button>
          <button @click="viewAttendanceReports" class="primary-button">View Attendance Reports</button>
        </div>

        <!-- Grade Submission Section -->
        <div class="admin-section">
          <h3>Grade Submission</h3>
          <p>Submit and manage student grades.</p>
          <button @click="enterGrades" class="primary-button">Enter Grades</button>
          <button @click="generateGradeReports" class="primary-button">Generate Grade Reports</button>
        </div>

        <!-- Curriculum Resources Section -->
        <div class="admin-section">
          <h3>Curriculum Resources</h3>
          <p>Access and manage curriculum materials.</p>
          <button @click="browseResources" class="primary-button">Browse Resources</button>
          <button @click="uploadMaterial" class="primary-button">Upload New Material</button>
        </div>

        <!-- Student Information Section -->
        <div class="admin-section">
          <h3>Student Information</h3>
          <p>Access and update student records.</p>
          <button @click="viewStudentProfiles" class="primary-button">View Student Profiles</button>
          <button @click="updateStudentInformation" class="primary-button">Update Student Information</button>
        </div>

        <!-- Incident Reporting Section -->
        <div class="admin-section">
          <h3>Incident Reporting</h3>
          <p>Report and track student incidents or issues.</p>
          <button @click="fileNewReport" class="primary-button">File New Report</button>
          <button @click="viewIncidentHistory" class="primary-button">View Incident History</button>
        </div>

        <!-- School Policies Section -->
        <div class="admin-section">
          <h3>School Policies</h3>
          <p>Access important school policies and procedures.</p>
          <div class="policy-list">
            <div class="policy-item" v-for="(policy, index) in policies" :key="index">
              {{ policy }}
            </div>
          </div>
          <button @click="viewAllPolicies" class="primary-button">View All Policies</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/TeacherSidebar.vue";

export default {
  name: "TeacherAdmin",
  components: {
    StudentHeader,
    Sidebar,
  },
  data() {
    return {
      policies: [
        "Student Conduct Policy",
        "Grading Guidelines",
        "Emergency Procedures",
        "Technology Use Policy",
      ],
    };
  },
  methods: {
    takeAttendance() {
      alert("Take Attendance clicked");
    },
    viewAttendanceReports() {
      alert("View Attendance Reports clicked");
    },
    enterGrades() {
      alert("Enter Grades clicked");
    },
    generateGradeReports() {
      alert("Generate Grade Reports clicked");
    },
    browseResources() {
      alert("Browse Resources clicked");
    },
    uploadMaterial() {
      alert("Upload New Material clicked");
    },
    viewStudentProfiles() {
      alert("View Student Profiles clicked");
    },
    updateStudentInformation() {
      alert("Update Student Information clicked");
    },
    fileNewReport() {
      alert("File New Report clicked");
    },
    viewIncidentHistory() {
      alert("View Incident History clicked");
    },
    viewAllPolicies() {
      alert("View All Policies clicked");
    },
  },
};
</script>

<style scoped>
.app-container {
  display: flex;
}

.content-container {
  flex: 1;
  margin-left: 250px; /* Ensure this matches the width of the sidebar */
  display: flex;
  flex-direction: column;
}

.content {
  padding: 20px;
  background-color: var(--background-color);
}

.admin-section {
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.primary-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #0033cc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.primary-button:hover {
  background-color: #002299;
}

.policy-list {
  margin-top: 10px;
}

.policy-item {
  background-color: var(--secondary);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}
</style>
