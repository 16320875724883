<template>
    <div class="markingSchemeContainer">
        <p>Upload a marking scheme for grading:</p>
        <br />
        <br />
        <input type="file" @change="onFileChange" accept="application/pdf" />
        <div v-if="loading" class="spinner">Loading...</div>
        <embed v-if="pdfUrl && !loading" :src="pdfUrl" type="application/pdf" />
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            pdfUrl: null,
            marchingSchemeId: null,
            loading: false,
        };
    },
    methods: {
        onFileChange(event) {
            const file = event.target.files[0];
            this.pdfUrl = URL.createObjectURL(file);
            if (file && file.type === "application/pdf") {
                // this.loading = true;
                // const formData = new FormData();
                // formData.append('file', file);

                // axios.post('http://127.0.0.1:5000/grader/encode-images', formData, {
                //     headers: {
                //         'Content-Type': 'multipart/form-data'
                //     }
                // })
                //     .then(response => {
                //         console.log('Response:', response.data);
                //         this.marchingSchemeId = response.data.marchingSchemeId;
                //         this.pdfUrl = URL.createObjectURL(file);
                //     })
                //     .catch(error => {
                //         console.error('Error:', error);
                //     })
                //     .finally(() => {
                //         this.loading = false;
                //     });

                this.$emit("file-uploaded", this.marchingSchemeId);
            }
        },
    },
    beforeDestroy() {
        // Revoke the object URL to avoid memory leaks
        if (this.pdfUrl) {
            URL.revokeObjectURL(this.pdfUrl);
        }
    },
};
</script>

<style scoped>
.markingSchemeContainer {
    padding: 5vh;
    text-align: center;
    color: var(--primary);
    height: 100%;
}

embed {
    width: 100%;
    height: 85vh;
    margin-top: 5vh;
}

.spinner {
    margin-top: 20px;
    font-size: 1.5em;
    color: var(--primary);
}
</style>
