<template>
    <div :class="['main-container', { collapsed: isCollapsed }]">
        <Sidebar @toggle="handleToggle" :collapsed="isCollapsed" />
        <div :class="['content-container', { collapsed: isCollapsed }]">
            <StudentHeader title="Classes" />
            <div v-if="isMainContent">
                <div class="top-bar">
                    <button class="timetable-button" @click="goToTimetable">
                        View Timetable
                        <span class="material-symbols-outlined timetable-icon">
                            schedule
                        </span>
                    </button>
                </div>
                <div class="content">
                    <div v-if="loading" class="loading">Loading classes...</div>
                    <div v-else-if="error" class="error">{{ error }}</div>
                    <div v-else class="classes-grid">
                        <div
                            class="class-card"
                            v-for="course in studentStore.courses"
                            :key="course.course_id"
                        >
                            <div class="title" @click="goToCourse(course)">
                                <p>{{ course.course_name }}</p>
                            </div>
                            <div class="instructor">
                                <p>
                                    {{ course.instructor }}
                                </p>
                            </div>
                            <!-- <Tooltip
                                class="aura-button"
                                text="Ask AURA"
                                position="top"
                            >
                                <span
                                    class="material-symbols-outlined"
                                    @click="askAura(course.name)"
                                >
                                    psychology
                                </span>
                            </Tooltip> -->
                        </div>
                    </div>
                </div>
            </div>

            <router-view v-else> </router-view>
        </div>
    </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import { useStudentStore } from "@/store/studentData";
import Tooltip from "@/components/Tooltip.vue";

export default {
    name: "StudentClasses",
    components: {
        StudentHeader,
        Sidebar,
        Tooltip,
    },
    data() {
        return {
            classes: [],
            loading: false,
            error: null,
            isCollapsed: false,
        };
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
        isMainContent() {
            return this.$route.path === "/classes";
        },
    },
    methods: {
        askAura(courseName) {
            this.$router.push("/chatbot");
        },
        handleToggle(collapsed) {
            this.isCollapsed = !collapsed;
        },
        goToCourse(course) {
            this.$router.push({
                name: "StudentCourse",
                params: { courseCode: course.course_code },
            });
        },
        goToTimetable() {
            this.$router.push(`/timetable`);
        },
    },
};
</script>

<style scoped>
.main-container {
    display: flex;
    transition: all 0.3s ease-in-out;
    font-family: var(--main-font);
    margin: 0;
}

.main-container.collapsed .content-container {
    margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
    margin-left: 18vw;
}

.content-container {
    flex: 1;
    transition: margin-left 0.3s ease-in-out;
    color: var(--primary);
}

.top-bar {
    height: 4vh;
    display: flex;
    justify-content: flex-end;
    margin-top: 2vh;
}

.timetable-button {
    margin-right: 1vw;
    margin-top: 2vh;
    background-color: var(--classi2);
    color: white;
    height: 4vh;
    width: 12vw;
    border: none;
    border-radius: 0.7vw;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8vw;
}

.timetable-icon {
    padding-left: 0.5vw;
}

.timetable-button:hover {
    opacity: 0.6;
}

.content {
    background-color: var(--background-color);
    margin-top: 2rem;
}

.classes-grid {
    padding: 2vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3vw;
}

.class-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30vh;
    padding: 0vw 3vw;
    background-color: var(--accent3);
    color: var(--primary);
    border-radius: 1rem;
    box-shadow: var(--box-shadow);
    position: relative;
    line-height: 1.5;
    z-index: 1;
}

.class-card:hover {
    scale: 1.05;
    transition: all ease-in-out 0.3s;
    z-index: 2;
}

.title {
    flex: 2;
    display: flex;
    font-size: 1.2vw;
    align-items: flex-end;
}

.title:hover {
    cursor: pointer;
    text-decoration: underline;
    opacity: 0.6;
}

.instructor {
    flex: 2;
    color: var(--accent4);
    font-size: 0.9vw;
}

.aura-button {
    flex: 1;
    display: flex;
    height: 4vh;
    width: 4vh;
    cursor: pointer;
    align-self: flex-end;
    align-items: flex-end;
}

.aura-button:hover {
    opacity: 0.6;
}
</style>
