<template>
    <TextbookPicker @textbook-selected="handleTextbookSelected" />
    <div class="chatContainer">
        <div ref="chatOutput" class="chatOutput">
            <div v-if="interactions.length === 0" class="receivedBox">
                Hello, my name is AURA. I am here to assist you in learning
                about your {{ selectedTextbookTitle }} textbook. <br />
                Here are some examples of things you can ask me:
                <div class="example-queries">
                    <p
                        v-for="(query, index) in selectedTextbookExampleQueries"
                        :key="index"
                    >
                        <i>- {{ query }}</i>
                    </p>
                </div>
            </div>
            <div v-for="(interaction, index) in interactions" :key="index">
                <p
                    v-if="interaction.sent"
                    class="sentBox"
                    v-html="formatReceived(interaction.sent)"
                ></p>
                <p
                    v-if="interaction.received"
                    class="receivedBox"
                    v-html="formatReceived(interaction.received)"
                ></p>
            </div>
        </div>
        <div class="inputRow">
            <textarea
                v-model="input"
                class="chatInput"
                placeholder="Chat with your textbook..."
                @keydown.enter.prevent="clickSendButton"
            ></textarea>
            <!-- <button
                ref="sendButton"
                class="sendButton"
                :disabled="isButtonDisabled"
                @click="sendMessage"
            > -->
            <span
                ref="sendButton"
                :disabled="isButtonDisabled"
                @click="sendMessage"
                class="material-symbols-outlined sendButton"
            >
                send
            </span>
            <!-- </button> -->
        </div>
    </div>
</template>

<script>
import io from "socket.io-client";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { throttle } from "lodash";
import TextbookPicker from "@/components/TextbookPicker.vue";
import { useStudentStore } from "@/store/studentData";

export default {
    components: {
        TextbookPicker,
    },
    emits: ["textbook-selected"],
    data() {
        return {
            selectedTextbookId: "",
            selectedTextbookTitle: "",
            selectedTextbookExampleQueries: [],
            socket: null,
            interactions: [],
            input: "",
            isButtonDisabled: false,
            isTypesetting: false,
        };
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
    },
    created() {
        this.initializeSocket();
    },
    methods: {
        initializeSocket() {
            this.socket = io(process.env.VUE_APP_API_BASE_URL, {
                transports: ["websocket"],
            });

            this.socket.on("connect", () => {
                console.log("WebSocket connected");
            });

            this.socket.on("response", (data) => {
                if (data.message === "[END]") {
                    this.isButtonDisabled = false;
                } else {
                    this.interactions[this.interactions.length - 1].received +=
                        data.message;
                    this.scrollToBottom();
                }
            });

            this.socket.on("disconnect", () => {
                console.log("WebSocket disconnected");
            });

            this.socket.on("connect_error", (error) => {
                console.error("WebSocket connection error:", error);
            });
        },
        handleTextbookSelected({ textbook_id, title, example_queries }) {
            this.selectedTextbookId = textbook_id;
            this.selectedTextbookTitle = title;
            this.selectedTextbookExampleQueries = example_queries;
            this.interactions = [];
            this.$emit("textbook-selected");
        },
        formatReceived(received) {
            const rawHtml = marked(received);
            return DOMPurify.sanitize(rawHtml);
        },
        sendMessage() {
            console.log(this.selectedTextbookId);
            if (this.input.trim() !== "") {
                this.isButtonDisabled = true;
                this.interactions.push({
                    sent: this.input,
                    received: "",
                });
                this.scrollToBottom();
                this.socket.emit("message", {
                    userId: this.studentStore.studentId,
                    firstName: this.studentStore.firstName,
                    textbookId: this.selectedTextbookId,
                    query: this.input,
                });
                this.input = "";
            }
        },
        clickSendButton() {
            this.$refs.sendButton.click();
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.chatOutput;
                container.scrollTop = container.scrollHeight;
            });
        },
        // typesetMath() {
        //     if (this.isTypesetting) return;

        //     this.isTypesetting = true;
        //     this.$nextTick(() => {
        //         if (window.MathJax) {
        //             window.MathJax.typesetPromise()
        //                 .then(() => {
        //                     this.isTypesetting = false;
        //                 })
        //                 .catch((err) => {
        //                     console.error("MathJax typesetting error:", err);
        //                     this.isTypesetting = false;
        //                 });
        //         }
        //     });
        // },
        typesetMath() {
            if (this.isTypesetting) return;

            this.isTypesetting = true;
            this.$nextTick(() => {
                if (window.MathJax) {
                    if (this.$refs.chatOutput) {
                        const latestInteraction =
                            this.$refs.chatOutput.lastElementChild;
                        window.MathJax.typesetPromise([latestInteraction])
                            .then(() => {
                                this.isTypesetting = false;
                            })
                            .catch((err) => {
                                console.error(
                                    "MathJax typesetting error:",
                                    err
                                );
                                this.isTypesetting = false;
                            });
                    }
                }
            });
        },
        throttledTypesetMath: throttle(function () {
            this.typesetMath();
        }, 1000),
    },
    watch: {
        interactions: {
            handler() {
                this.throttledTypesetMath();
            },
            deep: true,
        },
    },
    updated() {
        this.scrollToBottom();
        this.throttledTypesetMath();
    },
    mounted() {
        this.throttledTypesetMath();
    },
    beforeDestroy() {
        if (this.socket) {
            this.socket.disconnect();
        }
    },
};
</script>

<style scoped>
.chatContainer {
    width: 100%;
    margin: auto;
    font-size: 0.9vw;
}

.chatOutput {
    overflow-y: auto;
    box-sizing: border-box;
    height: 65vh;
    width: 100%;
    max-width: 100%;
    padding: 2% 3% 2% 3%;
    color: var(--secondary);
    border: 0.1rem solid var(--primary);
    border-radius: 1rem;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
}

.example-queries {
    margin-left: 3rem;
}

.receivedBox *,
.sentBox * {
    box-sizing: border-box;
}

.receivedBox,
.sentBox {
    line-height: 2;
    width: fit-content;
    max-width: 80%;
    padding: 2vh 3vw;
    margin-bottom: 2%;
    border-radius: 3rem;
}

.sentBox {
    margin-left: auto;
    margin-right: 0vw;
    background-color: var(--classi2);
    color: white;
}

.receivedBox {
    margin-right: auto;
    margin-left: 0vw;
    background-color: var(--primary);
    color: var(--secondary);
}

.inputRow {
    display: flex;
    width: 100%;
    height: 6vh;
    margin-top: 0.5vh;
    justify-content: space-between;
    align-items: center;
}

.chatInput {
    height: 100%;
    width: 89.5%;
    border-radius: 1rem;
    font-size: 0.8vw;
    padding: 1vh 2vw;
    box-sizing: border-box;
    resize: none;
    overflow: auto;
}

.chatInput:focus {
    border: 1px solid darkslategray;
    box-shadow: 0 0 10px var(--classi2);
}

.sendButton {
    cursor: pointer;
    display: flex;
    height: 100%;
    width: 10%;
    background-color: var(--classi2);
    color: white;
    border: none;
    border-radius: 1rem;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    transition: all ease 0.3s;
}

.sendButton:hover,
.sendButton:active {
    opacity: 0.6;
}
</style>
