<template>
    <div class="assignment-container">
        <div class="title-row">
            <span @click="goBack" class="material-symbols-outlined back-button">
                arrow_back_ios
            </span>
            <!-- <div class="title-content">
                <p class="title">
                    {{ assignment.title }}
                </p>
                <p @click="goToCourse" class="course">
                    <i>{{ assignment.course }}</i>
                </p>
            </div> -->

            <div class="progress-container">
                <div class="progress-bar">
                    <div
                        v-for="question in assignmentQuestions"
                        :class="[
                            completedQuestions[question.question_number]
                                ? 'complete'
                                : 'incomplete',
                            'progress-segment',
                        ]"
                    ></div>
                </div>
                <div class="progress-text">
                    Progress ({{
                        Object.values(this.completedQuestions).filter(
                            (value) => value === true
                        ).length
                    }}/{{ assignmentQuestions.length }})
                </div>
            </div>
            <div class="submit-div">
                <Tooltip
                    text="This assignment is past due or has been graded"
                    position="left"
                    customStyles="padding: 1vh 1vw; width: 12vw; text-align:center; font-size: 0.8vw;"
                    :show="pastDueOrGraded"
                >
                    <button
                        @click="submitAssignment"
                        @mouseover="submitHover"
                        :class="[
                            'submit-button',
                            { 'no-submit': pastDueOrGraded },
                        ]"
                    >
                        Submit
                    </button>
                </Tooltip>
            </div>
        </div>

        <div class="title-grade">
            <div class="title-content">
                <p class="title">
                    {{ assignment.title }}
                </p>
                <p @click="goToCourse" class="course">
                    <i>{{ assignment.course }}</i>
                </p>
            </div>
            <div>
                <div class="total-grade-value">
                    <span v-if="totalGrades.totalObtained">
                        {{ totalGrades.totalObtained }}
                    </span>
                    /
                    {{ totalGrades.totalPossible }}
                </div>
                <div class="total-grade-label"><i>Grade</i></div>
            </div>
        </div>

        <div v-for="question in assignmentQuestions" :key="question.questionId">
            <div class="question-container" ref="questionRef">
                <div class="question-title">
                    <div class="question-number">
                        Question {{ question.question_number }}
                    </div>
                    <div class="question-content">
                        {{ question.question_content }}
                        <Tooltip
                            text="Ask AURA how to improve"
                            :show="question.graded"
                            customStyles="padding: 1vh 1vw; width: 10vw; text-align:center; font-size: 0.6vw; border-radius: 2rem"
                            position="right"
                        >
                            <span
                                v-if="question.graded"
                                class="material-symbols-outlined aura-button"
                                @click="askAura()"
                            >
                                psychology
                            </span>
                        </Tooltip>
                    </div>
                </div>
                <Tooltip
                    v-if="question.graded"
                    :text="question.feedback"
                    :show="question.graded"
                    position="left"
                    customStyles="padding: 1vh 1vw; min-width: 10vw; max-width: 20vw; text-align:center; font-size: 0.8vw"
                >
                    <div :class="['grade', { graded: question.graded }]">
                        <div v-if="question.graded">
                            {{ formatFloat(question.grade) }}
                        </div>
                        /{{ question.marks_possible }}
                    </div>
                </Tooltip>
                <!-- <p class="word-count">Word Count: {{ question.word_count }}</p> -->
            </div>
            <Tiptap
                :editable="!question.graded"
                :content="question"
                class="answer-container"
                @click="scrollToEditor"
                @update:content="
                    (content) =>
                        handleContentUpdate(
                            content,
                            question.question_number,
                            question.question_id
                        )
                "
            />
            <!-- <hr class="question-separator" /> -->
        </div>
        <CustomAlert @ok="handleOk" @cancel="handleCancel" />
    </div>
</template>
<script>
import Tiptap from "@/components/Tiptap.vue";
import axiosInstance from "@/utils/axiosInstance";
import CustomAlert from "@/components/CustomAlert.vue";
import { useAlertStore } from "@/store/alertDialog";
import { useAssignmentStore } from "@/store/assignmentData";
import Tooltip from "@/components/Tooltip.vue";

export default {
    data() {
        return {
            assignment: {},
            questionsResponse: {},
            completedQuestions: {},
            isAlertVisible: false,
        };
    },
    components: {
        Tiptap,
        CustomAlert,
        Tooltip,
    },
    computed: {
        alertStore() {
            return useAlertStore();
        },
        assignmentStore() {
            return useAssignmentStore();
        },
        pastDueOrGraded() {
            return (
                new Date(this.assignment.due) < new Date() ||
                this.assignment.status === "Graded"
            );
        },
        assignmentQuestions() {
            return Object.values(this.questionsResponse);
        },
        totalGrades() {
            let possible = 0;
            let obtained = 0;
            for (const question of this.assignmentQuestions) {
                possible += parseFloat(question.marks_possible);
                obtained += parseFloat(question.grade);
            }

            if (!obtained) {
                obtained = "--";
            } else {
                obtained = obtained.toFixed(2);
            }

            return {
                totalPossible: possible.toFixed(2),
                totalObtained: obtained,
            };
        },
    },
    props: ["assignmentId"],
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        handleCancel() {
            console.log("Cancel");
        },
        handleOk() {
            console.log("Ok");
        },
        askAura() {
            this.alertStore.showAlert("Coming soon", "Close", "");
        },
        scrollToEditor(refName) {
            const element = this.$refs.questionRef.$el;
            if (element) {
                const elementRect = element.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.scrollY;
                const offsetPosition = absoluteElementTop - 2000;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        },
        formatFloat(value) {
            value = parseFloat(value);

            // Check if the value is an integer
            if (Number.isInteger(value)) {
                return value.toString();
            }

            // Convert to a fixed number of decimals, up to 2
            let formattedValue = value.toFixed(2);

            // Remove unnecessary trailing zeros
            if (formattedValue.endsWith("0")) {
                formattedValue = parseFloat(formattedValue).toFixed(1);
            }

            return formattedValue;
        },
        async getAssignmentQuestions() {
            const assignmentQuestionsResponse = await axiosInstance.post(
                "/students/get-assignment-questions",
                {
                    assignment_id: this.assignmentId,
                }
            );
            this.assignmentQuestions = assignmentQuestionsResponse.data;

            this.assignmentQuestions.forEach((question, index) => {
                if (question.response_content !== null) {
                    this.completedQuestions[question.question_number] = true;
                }
            });
        },
        async handleContentUpdate(jsonContent, questionNumber, questionId) {
            // If past due or graded no need to update responses
            if (this.pastDueOrGraded) {
                console.log("Nothing doing");
                return;
            }

            if (!jsonContent) {
                this.completedQuestions[questionNumber] = false;
            } else {
                this.completedQuestions[questionNumber] = true;
            }

            console.log("Content updated:", jsonContent);
            this.assignmentStore.updateResponseContent(
                this.assignmentId,
                jsonContent,
                questionNumber,
                questionId
            );
        },
        goToCourse() {
            console.log(this.assignment);
            this.$router.push({
                name: "StudentCourse",
                params: { courseCode: this.assignment.course_code },
            });
        },
        submitAssignment() {
            const complete = Object.values(this.completedQuestions).filter(
                (value) => value === true
            ).length;
            const incomplete = this.assignmentQuestions.length - complete;
            if (incomplete !== 0) {
                this.alertStore.showAlert(
                    `You have ${incomplete} incomplete questions. Are you sure you want to submit?`,
                    "Yes",
                    "No"
                );
            } else {
                this.alertStore.showAlert(
                    "Submit (and autograding) functionality coming soon",
                    "Close",
                    ""
                );
            }
        },
        submitHover() {
            console.log(this.pastDue);
        },
    },
    async mounted() {
        this.assignment = this.assignmentStore.assignments[this.assignmentId];

        this.questionsResponse =
            await this.assignmentStore.getAssignmentQuestions(
                this.assignmentId
            );

        this.assignmentQuestions.forEach((question, index) => {
            if (!question.response_content) {
                this.completedQuestions[question.question_number] = false;
            } else {
                this.completedQuestions[question.question_number] = true;
            }
        });
    },
};
</script>
<style scoped>
.assignment-container {
    padding-bottom: 5vh;
}
.title-row {
    display: flex;
    justify-content: space-between;
    height: 5vh;
    margin-top: 5vh;

    align-items: center;
}

.back-button {
    flex: 1;
    cursor: pointer;
    height: 5vh;
    width: 3vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 0.2rem solid var(--primary);
    border-radius: 0.7rem;
    margin-right: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.2rem;
    font-size: 1vw;
}

.back-button:hover {
    background-color: var(--primary);
    color: var(--secondary);
    transition: all ease 0.3s;
}

.course {
    cursor: pointer;
    color: var(--accent4);
    font-size: 0.9vw;
}

.course:hover {
    text-decoration: underline;
}

.progress-container {
    flex: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 3rem;
}

.progress-bar {
    display: flex;
    margin: 0 1vw;
    height: 1.5vh;
    width: 70%;
    border: 0.2rem solid var(--primary);
    border-radius: 1rem;
    overflow: hidden;
}

.progress-segment {
    flex: 1;
    border-right: 0.1rem solid var(--primary);
}

.progress-text {
    font-size: 1vw;
    width: fit-content;
}

.complete {
    background-color: var(--classi2);
}

.submit-div {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    height: inherit;
}

.submit-button {
    cursor: pointer;
    width: 7vw;
    height: 5vh;
    border: 0.2rem solid var(--primary);
    border-radius: 1rem;
    background-color: var(--secondary);
    color: var(--primary);
    transition: all ease 0.3s;
    font-size: 0.8vw;
}

.no-submit {
    cursor: default;
    opacity: 0.5;
}

.submit-button:hover {
    background-color: var(--primary);
    color: var(--secondary);
}

.no-submit:hover {
    pointer-events: none;
    background-color: var(--secondary);
    color: var(--primary);
}

.title {
    font-size: 1.5vw;
}

.title-grade {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 1.3vw;
    border-bottom: 0.1rem solid var(--primary);
    margin-top: 5vh;
    padding-bottom: 1vh;
}

.total-grade-label {
    text-align: right;
    color: var(--accent4);
    font-size: 0.9vw;
}

.question-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--classi2);
    color: white;
    border-radius: 1rem;
    box-shadow: var(--box-shadow);
    transition: all ease 0.3s;
    padding: 2vh 2vw;
    margin: 2rem 0 0.25rem 0;
    font-size: 1vw;
}

.question-title {
    flex: 5;
}

.question-number {
    color: darkgrey;
    font-size: 0.8vw;
}

.aura-button {
    text-align: center;
    padding-top: 0.5vh;
    margin-left: 1vw;
    height: 2vw;
    width: 2vw;
    border-radius: 50%;
    background-color: white;
    color: black;
    cursor: pointer;
    transition: background-color ease 0.3s;
    font-size: 1.3vw;
}

.aura-button:hover {
    background-color: black;
    color: white;
}

.question-content {
    font-size: 1.1vw;
}

.word-count {
    flex: 1;
}

.answer-container {
    margin-bottom: 2rem;
}

.grade {
    cursor: default;
    display: flex;
    color: var(--primary);
    justify-content: center;
    font-size: 1.2vw;
}

.graded {
    color: greenyellow;
}

.graded:hover {
    opacity: 0.7;
    text-decoration: underline;
}

.feedback {
    font-size: 0.8vw;
    padding-top: 1vh;
}

.question-separator {
    width: 40%;
    border: 0.1rem solid var(--primary);
    margin: 4vh auto;
}
</style>
