<template>
  <nav class="sidebar">
    <router-link to="/" class="logo">
      <span class="classi1">cl</span>
      <span class="classi2">a</span>
      <span class="classi1">ss</span>
      <span class="classi2">i</span>
    </router-link>
    <div class="user-info">
      <div class="profile-pic"></div>
      <p class="user-name">Teacher Name</p>
    </div>
    <router-link v-for="(item, index) in menuItems" :key="index" :to="item.route" class="nav-link"
      active-class="active-link" exact-active-class="exact-active-link">
      {{ item.title }}
    </router-link>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        { title: "AI Tools", route: "/AITools" },
        { title: "Schedule/Calendar", route: "/teacherSchedule" },
        { title: "Class Management", route: "/management" },
        { title: "Communication Tools", route: "/communication" },
        { title: "Administrative Services", route: "/teacherAdmin" },
        { title: "Professional Development", route: "/profDev" },
      ]
    }
  }
}
</script>

<style scoped>
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  height: 100%;
  width: 250px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  font-family: var(--accent-font);
}

.logo {
  margin-top: 0;
  margin-bottom: 5vh;
  font-size: 5vh;
  font-weight: 100;
  letter-spacing: 1vh;
  text-decoration: none;
}

.classi1 {
  color: var(--classi1);
  text-transform: none;
  /* Ensure text remains lowercase */
}

.classi2 {
  color: var(--classi2);
  text-transform: none;
  /* Ensure text remains lowercase */
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  font-weight: bold;
  color: var(--text-primary);
}

.nav-link {
  display: block;
  padding: 10px 0;
  text-decoration: none;
  color: var(--text-primary);
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  margin-bottom: 5px;
}

.nav-link:hover {
  background-color: var(--hover-background);
}

.active-link {
  background-color: var(--hover-background);
}

.exact-active-link {
  background-color: var(--hover-background);
}
</style>