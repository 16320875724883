<template>
    <div class="app-container">
        <Sidebar />
        <div class="content-container">
            <StudentHeader title="Interests" />
            <div class="content">
                <h1>My Interests</h1>
                <div class="interests-grid">
                    <div
                        v-for="(category, index) in interestCategories"
                        :key="index"
                        class="interest-category"
                    >
                        <h2>{{ category.name }}</h2>
                        <div class="interest-items">
                            <div
                                v-for="(item, itemIndex) in category.items"
                                :key="itemIndex"
                                class="interest-item"
                            >
                                <div class="item-icon">Icon</div>
                                <div class="item-name">{{ item }}</div>
                                <button
                                    @click="
                                        handleItemClick(category.name, item)
                                    "
                                    class="action-button"
                                >
                                    {{ getActionText(category.name) }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="personalization-section">
                    <h2>Personalize Your Interests</h2>
                    <button
                        @click="openPersonalizationModal"
                        class="personalize-button"
                    >
                        Update My Interests
                    </button>
                </div>
            </div>
        </div>

        <!-- Simple Modal -->
        <div v-if="showModal" class="modal">
            <div class="modal-content">
                <h2>{{ modalTitle }}</h2>
                <p>{{ modalContent }}</p>
                <button @click="closeModal" class="close-button">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import StudentHeader from "@/components/StudentHeader.vue";

export default {
    name: "StudentInterests",
    components: {
        Sidebar,
        StudentHeader,
    },
    data() {
        return {
            interestCategories: [
                {
                    name: "Games",
                    items: ["Math Blaster", "Word Puzzle", "Science Quiz"],
                },
                {
                    name: "Sports",
                    items: ["Basketball", "Soccer", "Swimming"],
                },
                {
                    name: "Clubs",
                    items: ["Debate Club", "Chess Club", "Robotics Club"],
                },
                {
                    name: "Art",
                    items: ["Painting", "Photography", "Sculpture"],
                },
            ],
            showModal: false,
            modalTitle: "",
            modalContent: "",
        };
    },
    methods: {
        handleItemClick(category, item) {
            this.modalTitle = `${category}: ${item}`;
            this.modalContent = `You clicked on ${item} in the ${category} category. This is where specific content or actions for ${item} would be displayed.`;
            this.showModal = true;
        },
        getActionText(category) {
            switch (category) {
                case "Games":
                    return "Play";
                case "Sports":
                    return "View";
                case "Clubs":
                    return "Join";
                case "Art":
                    return "Explore";
                default:
                    return "Select";
            }
        },
        openPersonalizationModal() {
            this.modalTitle = "Personalize Interests";
            this.modalContent =
                "Here you would be able to add or remove interests from your profile.";
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
    },
};
</script>

<style scoped>
.app-container {
    display: flex;
    height: 100vh;
}

.content-container {
    flex-grow: 1;
    margin-left: 250px; /* Adjust based on your sidebar width */
    display: flex;
    flex-direction: column;
}

.content {
    padding: 20px;
    overflow-y: auto;
    background-color: var(--background-color);
}

h1,
h2 {
    color: var(--text-primary);
}

.interests-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.interest-category {
    background-color: #90ee90; /* Light green background */
    border-radius: 8px;
    padding: 15px;
}

.interest-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.interest-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.item-icon {
    width: 40px;
    height: 40px;
    background-color: white;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 12px;
}

.item-name {
    flex-grow: 1;
    color: var(--text-primary);
}

.action-button,
.personalize-button,
.close-button {
    padding: 5px 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.action-button:hover,
.personalize-button:hover,
.close-button:hover {
    background-color: #333; /* Slightly lighter black on hover */
}

.personalization-section {
    margin-top: 30px;
    text-align: center;
}

.personalize-button {
    margin-top: 10px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: var(--background-color);
    color: var(--text-primary);
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
}
</style>
