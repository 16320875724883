<template>
    <div class="theme-switcher">
        <label class="switch">
            <input
                type="checkbox"
                @change="toggleTheme"
                :checked="theme === 'dark'"
            />
            <span class="slider">
                <span class="material-symbols-outlined sun">light_mode</span>
                <span class="material-symbols-outlined moon">dark_mode</span>
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: "ThemeSwitcher",
    data() {
        return {
            theme: "dark", // Set default theme to dark
        };
    },
    created() {
        const savedTheme = localStorage.getItem("theme");
        if (savedTheme) {
            this.theme = savedTheme;
        } else {
            localStorage.setItem("theme", this.theme); // Save default theme to local storage
        }
        document.documentElement.setAttribute("data-theme", this.theme);
    },
    methods: {
        toggleTheme() {
            this.theme = this.theme === "dark" ? "light" : "dark";
            document.documentElement.setAttribute("data-theme", this.theme);
            localStorage.setItem("theme", this.theme);
            this.$emit("themeChange", this.theme);
        },
    },
};
</script>

<style scoped>
.theme-switcher {
    display: flex;
    justify-content: flex-end;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: var(--classi2);
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.sun,
.moon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.sun {
    left: 8px;
}

.moon {
    right: 8px;
}

.material-symbols-outlined {
    font-size: 18px;
}
</style>
