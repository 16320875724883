<template>
    <div class="dropdown-container">
        <label :for="id">{{ label }}</label>
        <div class="select-wrapper">
            <select :id="id" v-model="selectedValue" @change="handleChange">
                <option
                    v-for="option in options"
                    :key="option.value"
                    :value="option.value"
                >
                    {{ option.text }}
                </option>
            </select>
            <span class="dropdown-arrow material-symbols-outlined"
                >expand_more</span
            >
        </div>
    </div>
</template>

<script>
export default {
    name: "Dropdown",
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
            validator(value) {
                return value.every(
                    (option) => "value" in option && "text" in option
                );
            },
        },
        value: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            selectedValue: this.value,
        };
    },
    watch: {
        value(newValue) {
            this.selectedValue = newValue;
        },
        options: {
            handler(newOptions) {
                if (!this.selectedValue && newOptions.length > 0) {
                    this.selectedValue = newOptions[0].value;
                    this.handleChange();
                }
            },
            immediate: true,
        },
    },
    methods: {
        handleChange() {
            this.$emit("change", this.selectedValue);
        },
    },
    mounted() {
        if (!this.selectedValue && this.options.length > 0) {
            this.selectedValue = this.options[0].value;
            this.handleChange();
        }
    },
};
</script>

<style scoped>
.dropdown-container {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
}

label {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
}

.select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}

select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 0.2rem solid var(--primary);
    border-radius: 0.5rem;
    appearance: none;
    background-color: var(--secondary);
    color: var(--primary);
    cursor: pointer;
    transition: all ease 0.3s;
}

select:focus {
    border-color: var(--classi2);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    outline: none;
}

.dropdown-arrow {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    pointer-events: none;
    color: var(--accent2);
}
</style>
