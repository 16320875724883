<template>
    <Header />
    <div class="graderContainer">
        <div class="submissionViewer">
            <SubmissionViewer @file-uploaded="handleSubmissionUploaded" />
            <button
                v-if="(submissionId != -1) & (markingSchemeUploaded === true)"
                class="gradeButton"
                @click="gradeSubmission"
            >
                Grade Submission
            </button>
        </div>
        <div class="submissionGrader">
            <LoadingSpinner :loading="loading" />
            <MarkingSchemeViewer
                v-if="!pdfText"
                @file-uploaded="handleMarkingSchemeUploaded"
            />
            <div v-html="pdfText"></div>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import SubmissionViewer from "@/components/SubmissionViewer.vue";
import MarkingSchemeViewer from "@/components/MarkingSchemeViewer.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import DOMPurify from "dompurify";

export default {
    name: "GraderDemo",
    components: {
        Header,
        SubmissionViewer,
        MarkingSchemeViewer,
        LoadingSpinner,
    },
    data() {
        return {
            submissionId: -1,
            pdfText: "",
            markingSchemeUploaded: false,
            loading: false,
        };
    },
    methods: {
        handleSubmissionUploaded(submissionId) {
            this.submissionId = submissionId;
        },
        handleMarkingSchemeUploaded() {
            this.markingSchemeUploaded = true;
            console.log(this.pdfText);
            console.log(this.markingSchemeUploaded);
        },
        async gradeSubmission() {
            if (this.submissionId === -1) {
                alert("Please upload a submission");
            } else {
                console.log(this.submissionId);
                this.loading = true;
                this.pdfText = ""; // Clear previous content if any

                try {
                    console.log(this.submissionId);
                    const response = await fetch(
                        "http://127.0.0.1:5000/grader/grade-submission",
                        {
                            method: "POST",
                            body: JSON.stringify({
                                submissionId: this.submissionId,
                            }),
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );

                    const reader = response.body.getReader();
                    const decoder = new TextDecoder();

                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;

                        const chunk = decoder.decode(value, { stream: true });
                        if (chunk) {
                            this.loading = false;
                            this.appendHTMLChunk(chunk);
                        }
                    }
                } catch (error) {
                    console.error("Error processing PDF:", error);
                } finally {
                    console.log(this.pdfText);
                }
            }
        },
        appendHTMLChunk(chunk) {
            // const sanitizedChunk = DOMPurify.sanitize(chunk);
            this.pdfText += chunk;
        },
    },
};
</script>

<style>
.graderContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-size: medium;
    font-family: Arial, Helvetica, sans-serif;
}

.submissionViewer {
    width: 45vw;
    height: 80vh;
    background-color: var(--accent1);
    border-radius: 5%;
    text-align: center;
    font-size: medium;
    font-family: Arial, Helvetica, sans-serif;
}

.gradeButton {
    width: 10vw;
    height: 5vh;
    background-color: white;
    border: none;
    border-radius: 5vw;
    cursor: pointer;
    font-size: medium;
    font-family: Arial, Helvetica, sans-serif;
}

.gradeButton:hover {
    background-color: black;
    color: white;
}

.submissionGrader {
    width: 45vw;
    height: 80vh;
    border: none;
    background-color: var(--accent3);
    border-radius: 5%;
    overflow-y: scroll;
}

.gradedResponse {
    height: fit-content;
    padding: 5%;
    margin-bottom: 2%;
    background-color: var(--accent3);
    border-radius: 3vw;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: var(--primary);
}
</style>
