<template>
    <div :class="['main-container', { collapsed: isCollapsed }]">
        <Sidebar @toggle="handleToggle" />
        <div :class="['content-container', { collapsed: isCollapsed }]">
            <StudentHeader title="Timetable" />
            <div class="title-row">
                <span
                    @click="goBack"
                    class="material-symbols-outlined back-button"
                >
                    arrow_back_ios
                </span>
            </div>
            <div class="content">
                <div v-if="loading" class="loading">Loading timetable...</div>
                <div v-else-if="error" class="error">{{ error }}</div>
                <div v-else class="timetable-grid">
                    <div class="grid-header">
                        <div class="grid-cell corner-cell">Period</div>
                        <div
                            v-for="day in uniqueDays"
                            :key="day"
                            class="grid-cell"
                        >
                            {{ day }}
                        </div>
                    </div>
                    <div
                        v-for="period in uniquePeriods"
                        :key="period"
                        class="grid-row"
                    >
                        <div class="grid-cell period-cell">
                            {{ period }}
                            <div class="period-time">
                                {{ formatTime(getPeriodStartTime(period)) }} -
                                {{ formatTime(getPeriodEndTime(period)) }}
                            </div>
                        </div>
                        <div
                            v-for="day in uniqueDays"
                            :key="day"
                            class="grid-cell"
                        >
                            <div
                                v-for="entry in getTimetableEntry(day, period)"
                                :key="entry.timetable_id"
                                :class="[
                                    'entry-card',
                                    { break: period === 'Break' },
                                ]"
                            >
                                <div class="subject">
                                    {{ entry.subject }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStudentStore } from "@/store/studentData";
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
    name: "StudentTimetable",
    components: {
        StudentHeader,
        Sidebar,
    },
    data() {
        return {
            isCollapsed: false,
            loading: false,
            error: null,
        };
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
        timetable() {
            return this.studentStore.timetable;
        },
        uniqueDays() {
            return [...new Set(this.timetable.map((entry) => entry.day))];
        },
        uniquePeriods() {
            return [...new Set(this.timetable.map((entry) => entry.period))];
        },
    },
    methods: {
        handleToggle(collapsed) {
            this.isCollapsed = collapsed;
        },
        getTimetableEntry(day, period) {
            return this.timetable.filter(
                (entry) => entry.day === day && entry.period === period
            );
        },
        getPeriodStartTime(period) {
            const entry = this.timetable.find(
                (entry) => entry.period === period
            );
            return entry ? entry.start_time : "";
        },
        getPeriodEndTime(period) {
            const entry = this.timetable.find(
                (entry) => entry.period === period
            );
            return entry ? entry.end_time : "";
        },
        formatTime(time) {
            return time ? time.slice(0, 5) : "";
        },
        goBack() {
            this.$router.go(-1);
        },
        // goToClass(course) {
        //     if (course != "Break") {
        //         this.$router.push(`/classes/${course}`);
        //     }
        // },
    },
};
</script>

<style scoped>
.main-container {
    display: flex;
    height: 100vh;
    transition: all 0.3s ease-in-out;
    font-family: var(--main-font);
}

.main-container.collapsed .content-container {
    margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
    margin-left: 18vw;
}

.content-container {
    flex: 1;
    transition: margin-left 0.3s ease-in-out;
    color: var(--primary);
}

.title-row {
    display: flex;
    height: 3vh;
    margin-bottom: 1rem;
    align-items: center;
}

.back-button {
    cursor: pointer;
    height: 4vh;
    width: 3vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid var(--primary);
    border-radius: 0.5rem;
    margin-right: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.2rem;
}

.back-button:hover {
    background-color: var(--primary);
    color: var(--secondary);
    transition: all ease 0.3s;
}

.content {
    background-color: var(--background-color);
}

.timetable-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.5rem;
}

.grid-header {
    display: contents;
}

.grid-row {
    display: contents;
}

.grid-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    text-align: center;
}

.corner-cell {
    border-right: 0.1rem solid var(--accent2);
    border-bottom: 0.1rem solid var(--accent2);
}

.period-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.period-time {
    font-size: 0.8rem;
    color: grey;
}

.entry-card {
    /* cursor: pointer; */
    display: flex;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 139, 139, 0.4);
    /* border-radius: 1rem; */
    align-items: center;
    justify-content: center;
}

.entry-card.break {
    cursor: default;
    background-color: var(--accent2);
    color: var(--secondary);
}

.entry-card:hover {
    transform: translateY(-5px);
    transition: all ease 0.3s;
}

.loading,
.error {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.2rem;
}

.error {
    color: var(--error-color);
}
</style>
