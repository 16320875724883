<template>
    <div class="app-container">
        <Sidebar />
        <div class="content-container">
            <StudentHeader title="Schedule" />
            <div class="content">
                <h2>{{ currentMonthYear }}</h2>
                <div class="calendar-navigation">
                    <button @click="changeMonth(-1)">&lt; Previous</button>
                    <button @click="goToCurrentMonth">Today</button>
                    <button @click="changeMonth(1)">Next &gt;</button>
                </div>
                <div v-if="loading" class="loading">Loading schedule...</div>
                <div v-else-if="error" class="error">{{ error }}</div>
                <div v-else class="calendar">
                    <div
                        class="calendar-day"
                        v-for="day in days"
                        :key="day.date"
                    >
                        <h4>{{ formatDate(day.date, "EEE d") }}</h4>
                        <div
                            v-for="event in day.events"
                            :key="event.name"
                            class="event"
                            :class="event.type"
                        >
                            {{ event.name }}
                        </div>
                    </div>
                </div>
                <button class="add-event-button" @click="addPersonalEvent">
                    Add Personal Event
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import { fetchStudentSchedule, fetchStudentClasses } from "@/testData";

export default {
    name: "StudentSchedule",
    components: {
        StudentHeader,
        Sidebar,
    },
    data() {
        return {
            currentDate: new Date(),
            days: [],
            loading: true,
            error: null,
        };
    },
    computed: {
        currentMonthYear() {
            return this.formatDate(this.currentDate, "MMMM yyyy");
        },
    },
    mounted() {
        this.fetchSchedule();
    },
    methods: {
        async fetchSchedule() {
            this.loading = true;
            this.error = null;
            try {
                const [scheduleEvents, classes] = await Promise.all([
                    fetchStudentSchedule("JS12345"),
                    fetchStudentClasses("JS12345"),
                ]);
                const classEvents = this.generateClassEvents(classes);
                const allEvents = [...scheduleEvents, ...classEvents];
                this.generateCalendarDays(allEvents);
                this.loading = false;
            } catch (error) {
                this.error = "Failed to load schedule. Please try again later.";
                this.loading = false;
            }
        },
        generateClassEvents(classes) {
            const events = [];
            classes.forEach((classItem) => {
                const days = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                ];
                classItem.schedule.days.forEach((day) => {
                    const dayIndex = days.indexOf(day);
                    if (dayIndex !== -1) {
                        let date = new Date(
                            this.currentDate.getFullYear(),
                            this.currentDate.getMonth(),
                            1
                        );
                        while (date.getDay() !== dayIndex) {
                            date.setDate(date.getDate() + 1);
                        }
                        while (
                            date.getMonth() === this.currentDate.getMonth()
                        ) {
                            events.push({
                                date: new Date(date),
                                name: `${classItem.name} - ${classItem.schedule.time}`,
                                type: "class",
                            });
                            date.setDate(date.getDate() + 7);
                        }
                    }
                });
            });
            return events;
        },
        generateCalendarDays(events) {
            const year = this.currentDate.getFullYear();
            const month = this.currentDate.getMonth();
            const daysInMonth = new Date(year, month + 1, 0).getDate();
            const firstDayOfMonth = new Date(year, month, 1).getDay();

            this.days = [];
            for (let i = 0; i < firstDayOfMonth; i++) {
                this.days.push({
                    date: new Date(year, month, -firstDayOfMonth + i + 1),
                    events: [],
                });
            }
            for (let i = 1; i <= daysInMonth; i++) {
                const date = new Date(year, month, i);
                const dayEvents = events.filter((event) => {
                    const eventDate = new Date(event.date);
                    return (
                        eventDate.getFullYear() === year &&
                        eventDate.getMonth() === month &&
                        eventDate.getDate() === i
                    );
                });
                this.days.push({ date, events: dayEvents });
            }
        },
        changeMonth(delta) {
            this.currentDate = new Date(
                this.currentDate.getFullYear(),
                this.currentDate.getMonth() + delta,
                1
            );
            this.fetchSchedule();
        },
        goToCurrentMonth() {
            this.currentDate = new Date();
            this.fetchSchedule();
        },
        formatDate(date, format) {
            return new Intl.DateTimeFormat("en-US", {
                weekday: format.includes("EEE") ? "short" : undefined,
                day: format.includes("d") ? "numeric" : undefined,
                month: format.includes("MMMM") ? "long" : undefined,
                year: format.includes("yyyy") ? "numeric" : undefined,
            }).format(new Date(date));
        },
        addPersonalEvent() {
            alert("Add Personal Event functionality to be implemented");
        },
    },
};
</script>

<style scoped>
.app-container {
    display: flex;
}

.content-container {
    flex: 1;
    margin-left: 250px; /* Ensure this matches the width of the sidebar */
    display: flex;
    flex-direction: column;
}

.content {
    padding: 20px;
    background-color: var(--background-color);
}

.calendar-navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.calendar-navigation button {
    padding: 5px 10px;
    background-color: var(--background-color);
    border: 1px solid var(--primary);
    border-radius: 5px;
    cursor: pointer;
}

.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
}

.calendar-day {
    background-color: var(--card-background);
    border-radius: 8px;
    padding: 10px;
    min-height: 100px;
}

.calendar-day h4 {
    margin: 0 0 10px 0;
    font-size: 1rem;
    color: var(--text-primary);
}

.event {
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
    font-size: 0.8rem;
    word-wrap: break-word;
}

.event.assignment {
    background-color: #f8d7da;
    color: #721c24;
}

.event.exam {
    background-color: #d4edda;
    color: #155724;
}

.event.personal,
.event.club {
    background-color: #d6d8db;
    color: #383d41;
}

.event.class {
    background-color: #cce5ff;
    color: #004085;
}

.event.academic {
    background-color: #fff3cd;
    color: #856404;
}

.add-event-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--primary);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-event-button:hover {
    background-color: var(--card-background);
    color: var(--text-primary);
}

.loading,
.error {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2em;
}

.error {
    color: red;
}
</style>
