<template>
    <div class="app-container">
      <Sidebar />
      <div class="content-container">
        <StudentHeader />
        <div class="teachersPageContainer">
            <button class="box" @click="goToGrader">Auto Grader</button>
            <button class="box" @click="">Lecture Summarization</button>
            <button class="box" @click="">Lecture Planning</button>
        </div>
      </div>
    </div>
  </template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/TeacherSidebar.vue";
import ChatBox from "@/components/ChatBox.vue";


export default {
    name: "AI Tools Page",
    components: {
        StudentHeader,
        Sidebar,
        ChatBox,
    },
    methods: {
        goToGrader() {
            this.$router.push("/grader")
        }
    }
};
</script>

<style scoped>
  .app-container {
    display: flex;
  }
  
  .content-container {
    flex: 1;
    margin-left: 250px; /* Ensure this matches the width of the sidebar */
    display: flex;
    flex-direction: column;
  }

  
.teachersPageContainer {
    padding: 20px;
    background-color: var(--background-color);
    display: grid;
    margin: auto;
    width: 80vw;
    height: 60vh;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    row-gap: 5vh;
    column-gap: 0px;
}

button {
    margin: auto;
    height: 30vh;
    width: 25vw;
    border: 3px double var(--primary);
    background-color: transparent;
    color: var(--primary);
    font-size: 1.5vh;
    font-weight: 500;
    letter-spacing: 0.2vh;
}

button:hover {
    background-color: var(--primary);
    border: none;
    color: var(--secondary);
    transition: background-color 0.3s ease;
    cursor: pointer;
}
</style>