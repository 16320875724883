<template>
    <div
        v-if="alertStore.isVisible"
        class="custom-alert-overlay"
        @click.self="emitCancel"
    >
        <div class="custom-alert">
            <p class="alert-text">{{ alertStore.text }}</p>
            <div class="button-group">
                <button
                    v-if="alertStore.cancelText"
                    class="cancel-button"
                    @click="emitCancel"
                >
                    {{ alertStore.cancelText }}
                </button>
                <button
                    v-if="alertStore.okText"
                    class="ok-button"
                    @click="emitOk"
                >
                    {{ alertStore.okText }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { useAlertStore } from "@/store/alertDialog";

export default {
    name: "CustomAlert",
    setup() {
        const alertStore = useAlertStore();
        return { alertStore };
    },
    methods: {
        emitOk() {
            this.$emit("ok");
            this.alertStore.hideAlert();
        },
        emitCancel() {
            this.$emit("cancel");
            this.alertStore.hideAlert();
        },
    },
};
</script>

<style scoped>
.custom-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.custom-alert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--background-color);
    color: var(--primary);
    height: 20vh;
    width: 20vw;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: var(--box-shadow);
    text-align: center;
}

.alert-text {
    margin-bottom: 2rem;
    font-size: 1rem;
}

.button-group {
    display: flex;
    justify-content: space-around;
}

.ok-button,
.cancel-button {
    height: 3vh;
    width: 5vw;
    background-color: var(--primary);
    color: var(--secondary);
    border: none;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.ok-button:hover,
.cancel-button:hover {
    background-color: var(--classi2);
    color: var(--primary);
}
</style>
