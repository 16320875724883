// src/testData.js

export const johnSnowData = {
    personalInfo: {
      id: "JS12345",
      firstName: "John",
      lastName: "Snow",
      fullName: "John Snow",
      dateOfBirth: "1995-05-15",
      email: "john.snow@example.edu",
      phone: "+1 (555) 123-4567",
      address: {
        street: "123 Castle Black Road",
        city: "Winterfell",
        state: "The North",
        zipCode: "12345",
        country: "Westeros"
      },
      emergencyContact: {
        name: "Arya Stark",
        relation: "Sister",
        phone: "+1 (555) 987-6543"
      },
      avatar: require('@/assets/JohnSnow.jpg')
    },
    
    academicInfo: {
      studentId: "JS12345",
      major: "Medieval Studies",
      minor: "Military Strategy",
      academicYear: "Senior",
      enrollmentDate: "2020-09-01",
      expectedGraduation: "2024-05-15",
      advisor: {
        name: "Dr. Aemon Targaryen",
        email: "aemon.targaryen@example.edu",
        office: "Maester's Tower, Room 101"
      },
      overallGPA: 3.75
    },
    
    currentSemester: {
      term: "Spring 2024",
      gpa: 3.8,
      courses: [
        {
          id: "MED401",
          code: "MED401",
          name: "Advanced Medieval Warfare",
          instructor: "Prof. Brynden Tully",
          credits: 3,
          grade: "A",
          progress: 90,
          schedule: {
            days: ["Monday", "Wednesday", "Friday"],
            time: "10:00 AM - 11:30 AM",
            location: "Castle Hall, Room 305"
          },
          assignments: [
            { name: "Midterm Paper: Siege Warfare Strategies", dueDate: "2024-03-15", grade: "A-" },
            { name: "Final Exam: Comprehensive Warfare Tactics", dueDate: "2024-05-10", grade: null }
          ],
          reportLink: "/reports/MED401"
        },
        {
          id: "HIS405",
          code: "HIS405",
          name: "History of the Night's Watch",
          instructor: "Dr. Jeor Mormont",
          credits: 3,
          grade: "B+",
          progress: 85,
          schedule: {
            days: ["Tuesday", "Thursday"],
            time: "2:00 PM - 3:30 PM",
            location: "Longclaw Hall, Room 101"
          },
          assignments: [
            { name: "Essay: Origins of the Night's Watch", dueDate: "2024-04-01", grade: "A" },
            { name: "Presentation: Famous Lord Commanders", dueDate: "2024-04-20", grade: null }
          ],
          reportLink: "/reports/HIS405"
        },
        {
          id: "POL302",
          code: "POL302",
          name: "Politics of the Seven Kingdoms",
          instructor: "Prof. Tyrion Lannister",
          credits: 3,
          grade: "A-",
          progress: 88,
          schedule: {
            days: ["Monday", "Wednesday"],
            time: "1:00 PM - 2:30 PM",
            location: "Red Keep, Room 202"
          },
          assignments: [
            { name: "Group Project: Simulated Peace Treaty", dueDate: "2024-04-10", grade: "A" },
            { name: "Final Paper: Analysis of Power Structures", dueDate: "2024-05-05", grade: null }
          ],
          reportLink: "/reports/POL302"
        },
        {
          id: "COM401",
          code: "COM401",
          name: "Advanced Combat Techniques",
          instructor: "Ser Barristan Selmy",
          credits: 4,
          grade: "A",
          progress: 95,
          schedule: {
            days: ["Tuesday", "Thursday"],
            time: "9:00 AM - 11:30 AM",
            location: "Training Grounds"
          },
          assignments: [
            { name: "Practical Exam: Sword Fighting", dueDate: "2024-03-30", grade: "A+" },
            { name: "Written Test: Battle Formations", dueDate: "2024-05-01", grade: null }
          ],
          reportLink: "/reports/COM401"
        }
      ]
    },
    
    pastSemesters: [
      {
        term: "Fall 2023",
        gpa: 3.7,
        courses: [
          { code: "MED301", name: "Medieval Diplomacy", credits: 3, grade: "B+" },
          { code: "HIS350", name: "History of House Stark", credits: 3, grade: "A" },
          { code: "PHY201", name: "Physics of Archery", credits: 4, grade: "A-" },
          { code: "LIT225", name: "Literature of the North", credits: 3, grade: "B" }
        ]
      }
      // Add more past semesters as needed
    ],
    
    interests: {
      academic: ["Medieval History", "Military Strategy", "Northern Folklore"],
      extracurricular: ["Sword Fighting", "Archery", "Horseback Riding", "Wolf Training"],
      clubs: [
        {
          name: "Night's Watch Society",
          role: "President",
          description: "Student organization dedicated to the history and traditions of the Night's Watch."
        },
        {
          name: "Winterfell Heritage Club",
          role: "Member",
          description: "Club focused on preserving and promoting Northern culture and history."
        }
      ]
    },
    
    schedule: {
      // This includes both academic and personal events
      events: [
        { date: '2024-06-01', name: 'Summer Semester Begins', type: 'academic' },
        { date: '2024-06-08', name: 'Medieval Warfare Essay Due', type: 'assignment' },
        { date: '2024-06-10', name: 'History Quiz', type: 'exam' },
        { date: '2024-06-15', name: 'Sword Practice with Arya', type: 'personal' },
        { date: '2024-06-20', name: 'Night\'s Watch Society Meeting', type: 'club' },
        { date: '2024-06-25', name: 'Battle Strategies Presentation', type: 'assignment' },
        { date: '2024-07-01', name: 'Winterfell Heritage Festival', type: 'personal' },
        { date: '2024-07-10', name: 'Midterm Exams Begin', type: 'exam' },
        { date: '2024-07-15', name: 'Wolf Training Session', type: 'personal' }
      ]
    },
    
    financialInfo: {
      tuition: {
        balance: 5000,
        dueDate: "2024-08-01"
      },
      scholarships: [
        { name: "Stark Family Scholarship", amount: 10000, year: "2023-2024" }
      ],
      financialAid: {
        status: "Approved",
        amount: 7500,
        type: "Federal Student Loan"
      }
    },
    
    healthAndWellness: {
      immunizationStatus: "Up to date",
      healthInsurance: {
        provider: "Westeros Health",
        policyNumber: "WH123456789"
      },
      dietaryRestrictions: ["None"]
    },
    
    accountSettings: {
      emailNotifications: true,
      darkMode: false,
      language: "en",
      twoFactorAuthEnabled: false
    }
  };
  
  // Export functions to simulate API calls
  
  export function fetchStudentProfile(studentId) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          personalInfo: johnSnowData.personalInfo,
          academicInfo: johnSnowData.academicInfo,
          accountSettings: johnSnowData.accountSettings
        });
      }, 300);
    });
  }
  
  export function fetchStudentClasses(studentId) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(johnSnowData.currentSemester.courses);
      }, 300);
    });
  }
  
  export function fetchStudentGrades(studentId) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          currentSemester: johnSnowData.currentSemester,
          pastSemesters: johnSnowData.pastSemesters,
          overallGPA: johnSnowData.academicInfo.overallGPA
        });
      }, 300);
    });
  }
  
  export function fetchStudentInterests(studentId) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(johnSnowData.interests);
      }, 300);
    });
  }
  
  export function fetchStudentSchedule(studentId, startDate, endDate) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(johnSnowData.schedule.events);
      }, 300);
    });
  }
  
  export function fetchStudentAdminInfo(studentId) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          personalInfo: johnSnowData.personalInfo,
          academicInfo: johnSnowData.academicInfo,
          financialInfo: johnSnowData.financialInfo,
          healthAndWellness: johnSnowData.healthAndWellness
        });
      }, 300);
    });
  }