<template>
    <div class="title-row">
        <span @click="goBack" class="material-symbols-outlined back-button">
            arrow_back_ios
        </span>
        <h1 class="course-title">{{ course.course_name }}</h1>
    </div>
    <LoadingSpinner v-if="loading" />
    <div v-else class="course-categories">
        <div class="category announcements">
            <h2>Announcements</h2>
            <div class="announcements-container">
                <div
                    v-for="announcement in announcements"
                    :key="announcement.announcement_id"
                    class="announcement-card"
                >
                    <div class="announcement-header">
                        <p>{{ announcement.title }}</p>
                        <p class="announcement-meta">
                            <span>{{
                                formatDate(announcement.created_at)
                            }}</span>
                        </p>
                    </div>
                    <div class="announcement-content">
                        <p>{{ announcement.content }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="category upcoming">
            <h2>Upcoming</h2>
            <div class="upcoming-container">
                <TaskList
                    class="assignment-card"
                    :shortened="true"
                    title=""
                    :tasks="tasks"
                    :filters="filters"
                    @assignmentActivated="goToAssignment"
                />
            </div>
        </div>
        <div class="category discussion-board">
            <h2>Discussion Board</h2>
            <div class="message-container" ref="messageContainer">
                <div v-for="(post, index) in discussionPosts" :key="index">
                    <div class="post-title-row">
                        <div class="post-name">
                            {{ post.first_name }} {{ post.last_name }}
                        </div>
                        <div class="post-time">
                            {{ formatDate(post.posted_at) }}
                        </div>
                    </div>
                    <div class="post-content">
                        {{ post.content }}
                    </div>
                </div>
            </div>
            <div class="message-input-container">
                <textarea
                    v-model="discussionInput"
                    class="message-input"
                    placeholder="Enter discussion post here..."
                    @keydown.enter.prevent="clickSendButton"
                ></textarea>
                <span
                    ref="sendButton"
                    class="material-symbols-outlined send-button"
                    @click="createDiscussionPost"
                >
                    send
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import Tooltip from "@/components/Tooltip.vue";
import axiosInstance from "@/utils/axiosInstance";
import TaskList from "./TaskList.vue";
import LoadingSpinner from "./LoadingSpinner.vue";
import { useAssignmentStore } from "@/store/assignmentData";
import { useStudentStore } from "@/store/studentData";

export default {
    name: "StudentCourse",
    data() {
        return {
            isCollapsed: false,
            loading: true,
            announcements: [],
            filters: {
                completion: {
                    graded: false,
                    submitted: false,
                    inProgress: false,
                    incomplete: false,
                },
                subjects: [],
                dueDate: {
                    today: false,
                    thisWeek: false,
                    thisMonth: true,
                },
            },
            discussionPosts: [],
            discussionInput: "",
        };
    },
    props: ["courseCode"],
    components: {
        StudentHeader,
        Sidebar,
        Tooltip,
        TaskList,
        LoadingSpinner,
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
        assignmentStore() {
            return useAssignmentStore();
        },
        tasks() {
            return Object.values(this.assignmentStore.assignments);
        },
        course() {
            return this.studentStore.getCourse(this.courseCode)[0];
        },
    },
    methods: {
        handleToggle(collapsed) {
            this.isCollapsed = collapsed;
        },
        goBack() {
            this.$router.go(-1);
        },
        clickSendButton() {
            this.$refs.sendButton.click();
        },
        sortPostsByDate(posts) {
            return posts.slice().sort((a, b) => {
                return new Date(a.posted_at) - new Date(b.posted_at);
            });
        },
        async getAnnouncements() {
            try {
                const announcementsResponse = await axiosInstance.post(
                    "/students/get-announcements",
                    {
                        course_code: this.courseCode,
                    }
                );
                this.announcements = announcementsResponse.data;
                this.loading = false;
            } catch (error) {
                console.log("Announcements not found");
            }
        },
        async getDiscussionPosts() {
            try {
                const postsResponse = await axiosInstance.post(
                    "/students/get-discussion-posts",
                    {
                        course_code: this.courseCode,
                    }
                );
                const sortedPosts = this.sortPostsByDate(postsResponse.data);
                this.discussionPosts = sortedPosts;

                this.loading = false;
            } catch (error) {
                console.log("Discussion posts not found");
            }
        },
        async createDiscussionPost() {
            const datetime = new Date().toISOString();
            const content = this.discussionInput;
            const post = {
                first_name: this.studentStore.firstName,
                last_name: this.studentStore.lastName,
                content: content,
                posted_at: datetime,
            };

            this.discussionPosts.push(post);
            this.discussionInput = "";
            this.scrollToBottom();
            try {
                const setResponse = await axiosInstance.post(
                    "/students/create-discussion-post",
                    {
                        course_code: this.courseCode,
                        content: content,
                        posted_at: datetime,
                    }
                );
            } catch (error) {
                console.log("Could not create discussion post", error);
            }
        },
        formatDate(date) {
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            };
            return new Date(date).toLocaleDateString(undefined, options);
        },
        goToAssignment(assignment) {
            this.$router.push({
                name: "StudentAssignment",
                params: { assignmentId: assignment.assignment_id },
            });
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.messageContainer;
                container.scrollTop = container.scrollHeight;
            });
        },
    },
    mounted() {
        this.getAnnouncements();
        this.getDiscussionPosts();
    },
};
</script>

<style scoped>
.title-row {
    display: flex;
    height: 5vh;
    margin-bottom: 1vh;
    align-items: center;
}

.spinner {
    margin: 20vh auto;
}

h1,
h2,
h3 {
    font-family: var(--accent-font);
    font-weight: 300;
}

.back-button {
    cursor: pointer;
    height: 4vh;
    width: 3vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid var(--primary);
    border-radius: 0.5rem;
    margin-right: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.2rem;
    font-size: 1vw;
}

.course-categories {
    height: 77vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: visible;
}

.announcements {
    display: flex;
    flex-direction: column;
    height: 40%;
    width: 49%;
    /* border-bottom: 0.1rem solid var(--accent1); */
    border-left: 0.2rem solid var(--classi2);
    /* background-color: var(--accent3);
    border-radius: 1rem;
    box-shadow: var(--box-shadow); */
    padding-left: 1rem;
}

.announcements-container {
    overflow: auto;
}

.upcoming {
    height: 40%;
    width: 49%;
    border-left: 0.2rem solid var(--classi2);
    /* background-color: var(--accent3);
    border-radius: 1rem;
    box-shadow: var(--box-shadow);
    padding: 2rem; */
    padding-left: 1rem;
    overflow: hidden;
}

.upcoming-container {
    height: 100%;
    overflow-y: scroll;
}

.discussion-board {
    margin-top: 2vh;
    height: 60%;
    width: 100%;
    background-color: var(--classi2);
    color: white;
    border-radius: 0.5rem;
    padding: 2vh 2vw;
    display: flex;
    flex-direction: column;
}

.message-container {
    flex: 9;
    padding: 2vh 0vw;
    overflow: auto;
}

.message-input-container {
    flex: 1;
    display: flex;
}

.message-input {
    flex: 9;
    background-color: var(--accent5);
    color: var(--primary);
    border: none;
    border-radius: 1rem;
    font-size: 1.2vh;
    padding: 1rem 2rem;
    box-sizing: border-box;
    resize: none;
    overflow: auto;
}

.post-title-row {
    display: flex;
    justify-content: space-between;
    font-size: 0.8vw;
    color: lightgrey;
}

.post-content {
    padding: 1vh 2vw;
    margin-bottom: 1vh;
    font-size: 0.9vw;
    border-bottom: 0.1rem solid lightgrey;
}

.send-button {
    flex: 1;
    cursor: pointer;
    display: flex;
    height: 100%;
    margin-left: 1vw;
    background-color: var(--secondary);
    color: var(--primary);
    border: none;
    border-radius: 1rem;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    transition: all ease 0.3s;
}

.announcement-card {
    /* border-radius: 1rem; */
    /* box-shadow: var(--box-shadow); */
    /* border-left: 0.5rem solid var(--classi2); */
    padding: 1vh;
    margin: 0vh 1vw;
    font-size: 0.8vw;
    transition: all ease 0.3s;
}

.announcement-card:hover {
    transform: translateY(-0.2rem);
}

.announcement-header {
    border-bottom: 0.05rem solid var(--primary);
    margin-bottom: 0.5vh;
    padding-bottom: 0.5vh;
}

.announcement-meta {
    font-size: 0.6vw;
    color: var(--accent4);
}

.assignment-card {
    /* border-radius: 1rem; */
    /* box-shadow: var(--box-shadow); */
    /* border-left: 0.5rem solid var(--classi2); */
    /* margin: 0.5rem 2rem; */
    transition: all ease 0.3s;
    overflow-x: hidden;
}

.back-button:hover {
    background-color: var(--primary);
    color: var(--secondary);
    transition: all ease 0.3s;
}
</style>
