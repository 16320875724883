<template lang="html">
    <Header />
    <LoadingSpinner v-if="loading" class="spinner" />
    <form v-if="!loading" class="loginContainer" @submit.prevent="login">
        <input type="text" v-model="email" placeholder="Email" />
        <input type="password" v-model="password" placeholder="Password" />
        <button type="submit">Login</button>
    </form>
</template>
<script>
import Header from "@/components/Header.vue";
import axiosInstance from "@/utils/axiosInstance";
import { useStudentStore } from "@/store/studentData";
import { useAssignmentStore } from "@/store/assignmentData";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
    name: "LoginPage",
    components: {
        Header,
        LoadingSpinner,
    },
    data() {
        return {
            email: "",
            password: "",
            loading: false,
        };
    },
    created() {
        this.studentStore = useStudentStore();
        this.assignmentStore = useAssignmentStore();
    },
    methods: {
        async login() {
            this.loading = true;
            var studentId = -1;
            // Login
            try {
                const loginResponse = await axiosInstance.post("/auth/login", {
                    email: this.email,
                    password: this.password,
                });

                studentId = loginResponse.data.id;
            } catch (error) {
                console.error("Invalid credentials", error);
                alert("Email address or password is incorrect");
            }

            await this.studentStore.retrieveStudentDataFromBackend();
            await this.assignmentStore.getAssignments();

            this.$router.push("/dashboard").then(() => {
                this.$nextTick(() => {
                    this.loading = false;
                });
            });
        },
    },
    async mounted() {
        if (this.studentStore.checkAuth()) {
            this.loading = true;
            await this.assignmentStore.getAssignments();
            this.loading = false;
            this.$router.push("/dashboard");
        }
    },
};
</script>

<style scoped>
.spinner {
    margin: 20vh auto;
}

.loginContainer {
    margin: auto;
    display: flex;
    width: 50vw;
    height: 70vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: all ease 0.3s;
}

input {
    width: 25vw;
    height: 5vh;
    margin: 1rem 0rem;
    padding: 1rem 3rem;
    background-color: var(--background-color);
    color: var(--primary);
    border: 0.15rem solid var(--primary);
    border-radius: 1rem;
    font-size: 0.8rem;
    outline: none;
}

input:focus {
    box-shadow: none;
    border: 0.15rem solid var(--classi2);
}

button {
    width: 25vw;
    height: 5vh;
    margin: 1vh 0vw;
    padding: 1vh 2vw;
    background-color: var(--classi2);
    color: white;
    border: none;
    border-radius: 1rem;
}

button:hover {
    cursor: pointer;
    opacity: 0.6;
}
</style>
