<template>
    <div class="tooltip-container">
        <div
            class="hover-target"
            @mouseover="showTooltip"
            @mouseleave="hideTooltip"
        >
            <slot></slot>
        </div>
        <div
            v-if="isTooltipVisible && show"
            class="tooltip"
            :style="tooltipStyle"
        >
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    name: "Tooltip",
    data() {
        return {
            isTooltipVisible: false,
        };
    },
    props: {
        text: {
            type: String,
            required: true,
        },
        show: {
            type: Boolean,
            default: true,
        },
        position: {
            type: String,
            default: "center",
            validator(value) {
                return ["left", "right", "center"].includes(value);
            },
        },
        customStyles: {
            type: String,
            default: "",
        },
    },
    computed: {
        tooltipStyle() {
            let baseStyle = {
                top: "100%",
                marginTop: "5px",
                opacity: "0.9",
                position: "absolute",
                backgroundColor: "var(--primary)",
                color: "var(--secondary)",
                padding: "5px 10px",
                borderRadius: "0.5rem",
                zIndex: "3",
                textAlign: "center",
                overflowWrap: "break-word",
            };

            if (this.position === "left") {
                baseStyle.right = "0";
                baseStyle.transform = "translateX(0)";
            } else if (this.position === "right") {
                baseStyle.left = "0";
                baseStyle.transform = "translateX(0)";
            } else {
                // center
                baseStyle.left = "50%";
                baseStyle.transform = "translateX(-50%)";
            }

            // Convert customStyles string to an object
            const customStyleObject = this.customStyles
                .split(";")
                .filter((style) => style.trim().length > 0)
                .reduce((styleObj, style) => {
                    const [property, value] = style.split(":");
                    if (property && value) {
                        styleObj[property.trim()] = value.trim();
                    }
                    return styleObj;
                }, {});

            return { ...baseStyle, ...customStyleObject };
        },
    },
    methods: {
        showTooltip() {
            if (this.show) {
                this.isTooltipVisible = true;
            }
        },
        hideTooltip() {
            this.isTooltipVisible = false;
        },
    },
};
</script>

<style scoped>
.tooltip-container {
    position: relative;
    display: inline-block; /* Ensures the container wraps around the content properly */
}

.hover-target {
    display: inline-block; /* Ensures the target content is treated as inline-block */
}

.tooltip {
    text-align: center;
    overflow-wrap: break-word;
}
</style>
