<template>
    <div>
        <p v-if="title !== ''" @click="toggleList" class="collapsible-header">
            {{ title }}
            <span class="material-symbols-outlined arrow-icon">
                {{ showList ? "keyboard_arrow_down" : "keyboard_arrow_right" }}
            </span>
        </p>
        <transition name="fade">
            <div v-if="showList" class="task-list">
                <div
                    v-for="task in filteredTasks"
                    :class="['task-container', task.status.replace(/ /g, '')]"
                    @click="goToAssignment(task)"
                >
                    <div class="task-card">
                        <div class="task-section">
                            <div class="task-label">Title</div>
                            <div class="task-main">{{ task.title }}</div>
                        </div>
                        <div v-if="!shortened" class="task-section">
                            <div class="task-label">Course</div>
                            <div class="task-main">{{ task.course }}</div>
                        </div>
                        <div v-if="!shortened" class="task-section">
                            <div class="task-label">Assigned</div>
                            <div class="task-main">
                                {{ formatDate(task.assigned) }}
                            </div>
                        </div>
                        <div class="task-section">
                            <div class="task-label">Due</div>
                            <div class="task-main">
                                {{ formatDate(task.due) }}
                            </div>
                        </div>
                    </div>
                    <div class="status-text">{{ task.status }}</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        tasks: Array,
        filters: Object,
        shortened: Boolean,
    },
    data() {
        return {
            showList: true,
        };
    },
    computed: {
        filteredTasks() {
            const filteredTasks = this.applyFilters(this.tasks, this.filters);
            const sortedTasks = filteredTasks.sort((a, b) => {
                const dateA = new Date(a.due);
                const dateB = new Date(b.due);

                // For past assignments return descending order, else ascending order
                if (this.title === "Past") {
                    return dateB - dateA;
                } else {
                    return dateA - dateB;
                }
            });
            return sortedTasks;
        },
    },
    methods: {
        toggleList() {
            this.showList = !this.showList;
        },
        goToAssignment(task) {
            this.$emit("assignmentActivated", task);
        },
        formatDate(dateString) {
            const options = { year: "numeric", month: "short", day: "numeric" };
            return new Date(dateString).toLocaleDateString();
        },
        applyFilters(tasks, filters) {
            let filteredTasks = tasks;
            const now = new Date();

            if (filters.completion.graded) {
                filteredTasks = filteredTasks.filter(
                    (task) => task.status === "Graded"
                );
            }

            if (filters.completion.submitted) {
                filteredTasks = filteredTasks.filter(
                    (task) => task.status === "Submitted"
                );
            }

            if (filters.completion.inProgress) {
                filteredTasks = filteredTasks.filter(
                    (task) => task.status === "In Progress"
                );
            }

            if (filters.completion.incomplete) {
                filteredTasks = filteredTasks.filter(
                    (task) => task.status === "Incomplete"
                );
            }

            if (filters.subjects.length > 0) {
                filteredTasks = filteredTasks.filter((task) =>
                    filters.subjects.includes(task.course)
                );
            }

            if (filters.dueDate.today) {
                filteredTasks = filteredTasks.filter((task) => {
                    const due = new Date(task.due);
                    return due.toDateString() === now.toDateString();
                });
            } else if (filters.dueDate.thisWeek) {
                const weekFromNow = new Date();
                weekFromNow.setDate(now.getDate() + 7);
                filteredTasks = filteredTasks.filter((task) => {
                    const due = new Date(task.due);
                    return due >= now && due <= weekFromNow;
                });
            } else if (filters.dueDate.thisMonth) {
                const MonthFromNow = new Date();
                MonthFromNow.setDate(now.getDate() + 31);
                filteredTasks = filteredTasks.filter((task) => {
                    const due = new Date(task.due);
                    return due >= now && due <= MonthFromNow;
                });
            }

            return filteredTasks;
        },
    },
};
</script>

<style scoped>
.collapsible-header {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.task-list {
    padding: 1rem;
    color: var(--primary);
    overflow-x: hidden;
}

.task-container {
    display: flex;
    align-items: center;
    border-radius: 1.2rem;
    box-shadow: var(--box-shadow);
    height: 7vh;
    margin-bottom: 1.5vh;
    overflow: visible;
    text-align: right;
    color: transparent;
    transition: all ease 0.3s;
}

.task-container:hover {
    color: white;
}

.status-text {
    font-size: 0.8vw;
    width: 15%;
    padding-right: 1rem;
    text-align: center;
}

.Graded {
    background-color: var(--graded);
}

.Submitted {
    background-color: var(--submitted);
}
.InProgress {
    background-color: var(--in-progress);
}
.Incomplete {
    background-color: var(--incomplete);
}

.task-card {
    min-width: 99.3%;
    height: 7vh;
    cursor: pointer;
    text-align: left;
    display: flex;
    /* margin-left: 0.15rem; */
    justify-content: space-between;
    color: var(--primary);
    align-items: center;
    background-color: var(--accent3);
    border-radius: 1rem;
    transition: all ease 0.3s;
}

.task-card:hover {
    /* transform: translateX(-25rem); */
    min-width: 85%;
    width: 85%;
    box-shadow: var(--box-shadow);
}

.task-section {
    /* display: flex;
    flex-direction: column; */
    flex: 1;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
}

.task-label {
    font-size: 0.7vw;
    color: var(--accent4);
}

.task-main {
    font-size: 0.8vw;
}

.task-card.completed-task {
    background-color: var(--accent1);
}

.task-card.incomplete-task.past {
    color: var(--primary);
}

.arrow-icon {
    cursor: pointer;
    font-size: 24px;
    margin-left: 5px;
    vertical-align: middle;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
