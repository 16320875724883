<template>
  <div class="app-container">
    <Sidebar />
    <div class="content-container">
      <TeacherHeader />
      <div class="content">
        <h2>June 2024</h2>
        <div class="calendar-navigation">
          <button @click="prevMonth">&lt; Previous</button>
          <button @click="currentMonth">Today</button>
          <button @click="nextMonth">Next &gt;</button>
        </div>
        <div class="calendar">
          <div class="calendar-day" v-for="day in days" :key="day.date">
            <h4>{{ day.dayName }}</h4>
            <div v-for="event in day.events" :key="event.name" class="event" :class="event.type">
              {{ event.name }}
            </div>
          </div>
        </div>
        <button class="add-event-button" @click="addEvent">Add Event or Project</button>
      </div>
    </div>
  </div>
</template>

<script>
import TeacherHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/TeacherSidebar.vue";

export default {
  name: "TeacherSchedule",
  components: {
    TeacherHeader,
    Sidebar,
  },
  data() {
    return {
      days: [
        { date: 1, dayName: 'Mon 1', events: [{ name: 'Ancient Civilizations Unit (Start)', type: 'project' }] },
        { date: 2, dayName: 'Tue 2', events: [] },
        { date: 3, dayName: 'Wed 3', events: [] },
        { date: 4, dayName: 'Thu 4', events: [] },
        { date: 5, dayName: 'Fri 5', events: [] },
        { date: 6, dayName: 'Sat 6', events: [] },
        { date: 7, dayName: 'Sun 7', events: [] },
        { date: 8, dayName: 'Mon 8', events: [{ name: 'Math HW Due', type: 'due' }] },
        { date: 9, dayName: 'Tue 9', events: [] },
        { date: 10, dayName: 'Wed 10', events: [{ name: 'History Quiz', type: 'quiz' }] },
        { date: 11, dayName: 'Thu 11', events: [] },
        { date: 12, dayName: 'Fri 12', events: [{ name: 'Ancient Civilizations Unit (End)', type: 'project' }] },
        { date: 13, dayName: 'Sat 13', events: [] },
        { date: 14, dayName: 'Sun 14', events: [] },
        // Add more days as needed
      ],
    };
  },
  methods: {
    prevMonth() {
      alert('Previous month clicked');
    },
    currentMonth() {
      alert('Current month clicked');
    },
    nextMonth() {
      alert('Next month clicked');
    },
    addEvent() {
      alert('Add Event or Project button clicked');
    },
  },
};
</script>

<style scoped>
.app-container {
  display: flex;
}

.content-container {
  flex: 1;
  margin-left: 250px; /* Ensure this matches the width of the sidebar */
  display: flex;
  flex-direction: column;
}

.content {
  padding: 20px;
  background-color: var(--background-color);
}

.calendar-navigation {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.calendar-navigation button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: var(--background-color);
  border: 1px solid var(--primary);
  border-radius: 5px;
  cursor: pointer;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.calendar-day {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}

.calendar-day h4 {
  margin: 0;
  font-size: 1rem;
  color: var(--text-primary);
}

.event {
  margin-top: 5px;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
}

.event.due {
  background-color: #f8d7da;
  color: #721c24;
}

.event.quiz {
  background-color: #d4edda;
  color: #155724;
}

.event.project {
  background-color: #cce5ff;
  color: #004085;
}

.add-event-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #0033cc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-event-button:hover {
  background-color: #002299;
}
</style>
