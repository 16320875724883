<template>
  <div class="app-container">
    <Sidebar />
    <div class="content-container">
      <StudentHeader />
      <div class="content">
        <h2>Administrative Services</h2>
        <div class="admin-section">
          <h3>Payment Portal</h3>
          <p>Manage your tuition and fee payments here.</p>
          <div class="payment-info">
            <strong>Next Payment Due:</strong> July 1, 2024 <br>
            <strong>Amount:</strong> $1,500
          </div>
          <ul>
            <li><a href="#">Make a Payment</a></li>
            <li><a href="#">View Payment History</a></li>
          </ul>
        </div>

        <div class="admin-section">
          <h3>Student Records</h3>
          <p>Access and manage your official student records.</p>
          <ul>
            <li><a href="#">Request Transcript</a></li>
            <li><a href="#">Update Personal Information</a></li>
            <li><a href="#">View Enrollment Verification</a></li>
          </ul>
        </div>

        <div class="admin-section">
          <h3>Academic Services</h3>
          <p>Access important academic services and resources.</p>
          <ul>
            <li><a href="#">Course Registration</a></li>
            <li><a href="#">Degree Audit</a></li>
            <li><a href="#">Academic Calendar</a></li>
          </ul>
        </div>

        <div class="admin-section">
          <h3>Support Services</h3>
          <p>Get help with various student services and issues.</p>
          <ul>
            <li><a href="#">IT Help Desk</a></li>
            <li><a href="#">Student Counseling</a></li>
            <li><a href="#">Report an Issue</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "StudentAdmin",
  components: {
    StudentHeader,
    Sidebar,
  },
};
</script>

<style scoped>
.app-container {
  display: flex;
}

.content-container {
  flex: 1;
  margin-left: 250px; /* Ensure this matches the width of the sidebar */
  display: flex;
  flex-direction: column;
}

.content {
  padding: 20px;
  background-color: var(--background-color);
}

.admin-section {
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-section h3 {
  margin-top: 0;
}

.payment-info {
  background-color: var(--secondary);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

a {
  color: #0033cc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
