<template>
    <div :class="['main-container', { collapsed: isCollapsed }]">
        <Sidebar @toggle="handleToggle" :collapsed="isCollapsed" />
        <div :class="['content-container', { collapsed: isCollapsed }]">
            <StudentHeader title="Calendar" />
            <LoadingSpinner class="spinner" v-if="loading" />
            <div v-else class="content">
                <div class="top-bar">
                    <h2>{{ currentMonthYear }}</h2>
                    <span
                        class="material-symbols-outlined add-icon"
                        @click="addPersonalEvent"
                    >
                        add
                    </span>
                </div>
                <div class="calendar-navigation">
                    <button
                        @click="changeMonth(-1)"
                        class="material-symbols-outlined"
                    >
                        chevron_left
                    </button>
                    <button @click="goToCurrentMonth">Today</button>
                    <button
                        @click="changeMonth(1)"
                        class="material-symbols-outlined"
                    >
                        chevron_right
                    </button>
                </div>
                <div v-if="loading" class="loading">Loading assignments...</div>
                <div v-else-if="error" class="error">{{ error }}</div>
                <div v-else class="calendar">
                    <div
                        class="calendar-day"
                        v-for="day in days"
                        :key="day.date"
                        :class="{ today: isToday(day.date) }"
                    >
                        <p>{{ formatDate(day.date, "EEE d") }}</p>
                        <div
                            v-for="task in day.tasks"
                            :key="task.title"
                            class="task-card"
                            @click="goToAssignment(task)"
                        >
                            <div class="task-title">{{ task.title }}</div>
                            <div class="task-course">{{ task.course }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import axiosInstance from "@/utils/axiosInstance";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useAssignmentStore } from "@/store/assignmentData";

export default {
    name: "StudentCalendar",
    components: {
        StudentHeader,
        Sidebar,
        LoadingSpinner,
    },
    data() {
        return {
            currentDate: new Date(),
            days: [],
            tasks: [],
            loading: true,
            error: null,
            isCollapsed: false,
        };
    },
    computed: {
        assignmentsStore() {
            return useAssignmentStore();
        },
        assignments() {
            return Object.values(this.assignmentsStore.assignments);
        },
        currentMonthYear() {
            return this.formatDate(this.currentDate, "MMMM yyyy");
        },
    },
    mounted() {
        this.generateCalendarDays(this.assignments);
        this.loading = false;
    },
    methods: {
        handleToggle(collapsed) {
            this.isCollapsed = !collapsed;
        },
        generateCalendarDays(assignments) {
            const year = this.currentDate.getFullYear();
            const month = this.currentDate.getMonth();
            const daysInMonth = new Date(year, month + 1, 0).getDate();
            const firstDayOfMonth = new Date(year, month, 1).getDay();

            this.days = [];
            for (let i = 0; i < firstDayOfMonth; i++) {
                this.days.push({
                    date: new Date(year, month, -firstDayOfMonth + i + 1),
                    tasks: [],
                });
            }
            for (let i = 1; i <= daysInMonth; i++) {
                const date = new Date(year, month, i);
                const dayTasks = assignments.filter((task) => {
                    const taskDate = new Date(task.due);
                    return (
                        taskDate.getFullYear() === year &&
                        taskDate.getMonth() === month &&
                        taskDate.getDate() === i
                    );
                });
                this.days.push({ date, tasks: dayTasks });
            }
        },
        changeMonth(delta) {
            this.currentDate = new Date(
                this.currentDate.getFullYear(),
                this.currentDate.getMonth() + delta,
                1
            );
            this.generateCalendarDays(this.assignments);
        },
        goToCurrentMonth() {
            this.currentDate = new Date();
            this.generateCalendarDays(this.assignments);
        },
        formatDate(date, format) {
            return new Intl.DateTimeFormat("en-US", {
                weekday: format.includes("EEE") ? "short" : undefined,
                day: format.includes("d") ? "numeric" : undefined,
                month: format.includes("MMMM") ? "long" : undefined,
                year: format.includes("yyyy") ? "numeric" : undefined,
            }).format(new Date(date));
        },
        isToday(date) {
            const today = new Date();
            return (
                date.getFullYear() === today.getFullYear() &&
                date.getMonth() === today.getMonth() &&
                date.getDate() === today.getDate()
            );
        },
        addPersonalEvent() {
            alert("Coming soon");
        },
        goToAssignment(assignment) {
            this.$router.push({
                name: "StudentAssignment",
                params: { assignmentId: assignment.assignment_id },
                query: {
                    title: assignment.title,
                    course: assignment.course,
                },
            });
        },
    },
};
</script>

<style scoped>
.main-container {
    display: flex;
    transition: all 0.3s ease-in-out;
    font-family: var(--main-font);
    color: var(--primary);
}

.main-container.collapsed .content-container {
    margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
    margin-left: 18vw;
}

.content-container {
    flex: 1;
    transition: margin-left 0.3s ease-in-out;
}

.spinner {
    margin: 20vh auto;
}

.content {
    padding: 1rem;
    background-color: var(--background-color);
}

.top-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.add-icon {
    display: flex;
    height: 2vw;
    width: 2vw;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0.3rem;
    background-color: var(--classi2);
    color: white;
}

.add-icon:hover {
    opacity: 0.6;
}

.calendar-navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.calendar-navigation button {
    padding: 0.5rem 1rem;
    background-color: var(--background-color);
    color: var(--primary);
    border: 0.1rem solid var(--primary);
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all ease 0.3s;
}

.calendar-navigation button:hover {
    background-color: var(--primary);
    color: var(--secondary);
}

.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.8rem;
    color: var(--primary);
}

.calendar-day {
    /* border: 0.05rem solid var(--primary); */
    border-radius: 0.25rem;
    background-color: var(--classi2);
    color: white;
    padding: 0.5rem;
    height: 15vh;
    overflow-y: auto;
}

.calendar-day.today {
    border: 0.2rem solid var(--primary);
}

.task-card {
    cursor: pointer;
    font-size: small;
    background-color: var(--primary);
    color: var(--secondary);
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    margin: 0.5rem 0rem;
    font-size: 0.9vw;
    box-shadow: var(--box-shadow);
    transition: transform 0.2s, box-shadow 0.2s;
}

.task-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--box-shadow);
}

.task-course {
    color: var(--secondary);
    font-style: italic;
    font-size: 0.7vw;
}
</style>
