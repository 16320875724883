<template>
    <transition name="slide-fade">
      <div v-if="!appSelected" class="teacher-page">
        <div class="main-content">
          <StudentHeader />
          <div class="dashboard-grid">
            <div v-for="(item, index) in menuItems" :key="index" class="box" @click="selectApp(item.route)">
              <h2>{{ item.title }}</h2>
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  
    <transition name="slide-fade">
      <div v-if="appSelected" class="app-page">
        <Sidebar />
        <div class="main-content">
          <StudentHeader />
          <router-view />
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import StudentHeader from "@/components/StudentHeader.vue";
  import Sidebar from "@/components/Sidebar.vue";
  
  export default {
    name: "TeacherPage",
    components: {
      StudentHeader,
      Sidebar,
    },
    data() {
      return {
        menuItems: [
          { title: "AI Tools", description: "AutoGrader, lecture summarrization, Aura-T and Aura setup", route: "/AITools" },
          { title: "Schedule/Calendar", description: "Manage your academic calendar", route: "/teacherSchedule" },
          { title: "Class Management", description: "Manage your classes", route: "/management" },
          { title: "Communication Tools", description: "Communicate with your students", route: "/communication" },
          { title: "Administrative Services", description: "Access school services", route: "/teacherAdmin" },
          { title: "Professional Development", description: "Plan your future education", route: "/profDev" },
        ],
        appSelected: false,
      };
    },
    methods: {
      selectApp(route) {
        this.appSelected = true;
        this.$nextTick(() => {
          this.$router.push(route);
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .teacher-page, .app-page {
    display: flex;
    height: 100vh;
  }
  
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
  }
  
  .dashboard-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    flex-grow: 1;
  }
  
  .box {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .box:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .box h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: var(--primary);
  }
  
  .box p {
    font-size: 0.9rem;
    color: var(--text-secondary);
  }
  
  /* Transition animations */
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.5s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
    transform: translateX(100%);
    opacity: 0;
  }
  </style>
  