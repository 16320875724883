<template>
    <div :class="['main-container', { collapsed: isCollapsed }]">
        <Sidebar
            @themeChange="handleThemeChange"
            @toggle="handleToggle"
            :collapsed="isCollapsed"
        />
        <div :class="['content-container', { collapsed: isCollapsed }]">
            <Transition name="slide-in">
                <div>
                    <StudentHeader
                        v-if="typingComplete"
                        :title="`Hello, ${studentStore.firstName}`"
                    />
                    <LoadingSpinner
                        class="spinner"
                        v-if="loading && typingComplete"
                    />
                    <div
                        v-if="renderContent && !loading && isMainContent"
                        class="content"
                    >
                        <div class="column1">
                            <div class="completed-assignments">
                                <CompletedAssignmentsChart
                                    class="donut-chart"
                                    :assignmentsData="chartData"
                                    :pieBorderColor="pieBorderColor"
                                />
                                <!-- <p>Assignments Status</p> -->
                            </div>
                            <div class="wotd">
                                <p class="wotd-title">Word of the Day</p>
                                <hr class="wotd-hr" />
                                <div class="wotd-content">
                                    <span class="wotd-word">
                                        {{ wordOfTheDay.word }}
                                    </span>
                                    <p class="wotd-info">
                                        [{{ wordOfTheDay.pronunciation }}] |
                                        <i>{{ wordOfTheDay.word_type }}</i>
                                    </p>
                                </div>
                                <p class="wotd-meaning">
                                    {{ wordOfTheDay.meaning }}
                                    <br />
                                    <i
                                        >"{{ wordOfTheDay.example_sentence }}"
                                    </i>
                                </p>
                            </div>
                        </div>
                        <div class="column2">
                            <div class="notice-board">
                                <p class="notice-board-title">Notice Board</p>
                                <ul>
                                    <li
                                        v-for="notice in notices"
                                        :key="notice.id"
                                        class="notice-item"
                                    >
                                        <div class="notice-title">
                                            <span
                                                class="material-symbols-outlined pin"
                                            >
                                                keep
                                            </span>
                                            <span>{{ notice.title }}</span>
                                        </div>
                                        <div class="notice-item-content">
                                            {{ notice.description }}
                                        </div>
                                        <!-- <small>{{ notice.date }}</small> -->
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
            <Transition name="slide-out">
                <div v-if="!typingComplete" class="welcome-message">
                    <Typewriter :text="`Hello, ${studentStore.firstName}`" />
                    <div class="welcome-line">
                        <Typewriter text="Welcome to " :offset="2000" />
                        <Typewriter
                            @typing-complete="showDashboard"
                            text=""
                            :classi="true"
                            :offset="3500"
                        />
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import { useStudentStore } from "@/store/studentData";
import Typewriter from "@/components/Typewriter.vue";
import CompletedAssignmentsChart from "@/components/CompletedAssignmentsChart.vue";
import axiosInstance from "@/utils/axiosInstance";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useAssignmentStore } from "@/store/assignmentData";

export default {
    name: "StudentDashboard",
    components: {
        StudentHeader,
        Sidebar,
        LoadingSpinner,
        Typewriter,
        CompletedAssignmentsChart,
    },
    data() {
        return {
            loading: true,
            isCollapsed: true,
            typingComplete: false,
            renderContent: false,
            isMainContent: true,
            chartData: [],
            wordOfTheDay: {},
            theme: localStorage.getItem("theme"),
            notices: [
                {
                    id: 1,
                    title: "School Assembly",
                    description:
                        "There will be a school assembly on Monday at 8:00 AM in the main hall.",
                    date: "2024-08-10",
                },
                {
                    id: 2,
                    title: "Parent-Teacher Conference",
                    description:
                        "Parent-Teacher conferences will be held next Friday from 3:00 PM to 6:00 PM.",
                    date: "2024-08-15",
                },
                {
                    id: 3,
                    title: "Science Fair",
                    description:
                        "The annual science fair will take place on September 1st. All students are encouraged to participate.",
                    date: "2024-09-01",
                },
            ],
        };
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
        assignmentStore() {
            return useAssignmentStore();
        },
        assignments() {
            return this.assignmentStore.assignments;
        },
        pieBorderColor() {
            return this.theme === "dark" ? "black" : "white";
        },
    },
    methods: {
        handleToggle(collapsed) {
            this.isCollapsed = !collapsed;
        },
        handleThemeChange(currentTheme) {
            this.theme = currentTheme;
        },
        async showDashboard() {
            this.typingComplete = true;
            await this.delay(200);
            this.isCollapsed = false;
            await this.delay(200);
            this.renderContent = true;
        },
        delay(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        handleAssignmentsClick(status) {
            this.$router.push({
                name: "StudentAssignments",
                query: { selectedFilters: status },
            });
        },
        async getWordOfTheDay() {
            try {
                const wordResponse = await axiosInstance.get(
                    "/students/get-word-of-the-day"
                );
                this.wordOfTheDay = wordResponse.data;
            } catch (error) {
                console.log("Word not found", error);
            }
        },
        getAssignmentsStatus() {
            var graded = 0;
            var submitted = 0;
            var inprogress = 0;
            var incomplete = 0;

            Object.values(this.assignments).forEach((item) => {
                switch (item.status) {
                    case "Graded":
                        graded += 1;
                        break;
                    case "Submitted":
                        submitted += 1;
                        break;
                    case "In Progress":
                        inprogress += 1;
                        break;
                    case "Incomplete":
                        incomplete += 1;
                        break;
                    default:
                        break;
                }
            });

            this.chartData = [
                {
                    label: "Graded",
                    color: "rgb(115, 179, 115)",
                    // hoverColor: "darkgreen",
                    value: graded,
                    total: graded + submitted + inprogress + incomplete,
                    onClick: () => this.handleAssignmentsClick("graded"),
                },
                {
                    label: "Submitted",
                    color: "rgb(118, 161, 187)",
                    // hoverColor: "slateblue",
                    value: submitted,
                    total: graded + submitted + inprogress + incomplete,
                    onClick: () => this.handleAssignmentsClick("submitted"),
                },
                {
                    label: "In Progress",
                    color: "rgb(205, 205, 131)",
                    // hoverColor: "darkyellow",
                    value: inprogress,
                    total: graded + submitted + inprogress + incomplete,
                    onClick: () => this.handleAssignmentsClick("inProgress"),
                },
                {
                    label: "Incomplete",
                    color: "rgb(203, 114, 114)",
                    // hoverColor: "blue",
                    value: incomplete,
                    total: graded + submitted + inprogress + incomplete,
                    onClick: () => this.handleAssignmentsClick("incomplete"),
                },
            ];
        },
    },
    async mounted() {
        const welcomed = localStorage.getItem("welcomed");

        if (!welcomed) {
            localStorage.setItem("welcomed", true);
        } else {
            this.typingComplete = true;
            this.isCollapsed = false;
            this.renderContent = true;
        }

        await this.getWordOfTheDay();
        if (!sessionStorage.getItem("assignmentData")) {
            await this.assignmentStore.getAssignments();
        }

        this.getAssignmentsStatus();
        this.loading = false;
    },
};
</script>

<style scoped>
.main-container {
    display: flex;
    transition: all 0.3s ease-in-out;
    font-family: var(--main-font);
}

.main-container.collapsed .content-container {
    margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
    margin-left: 18vw;
}

.content-container {
    flex: 1;
    transition: margin-left 0.3s ease-in-out;
    color: var(--primary);
}

.spinner {
    margin: 20vh auto;
}

.welcome-message {
    margin-top: 30vh;
    margin-left: 10vw;
}

.welcome-line {
    display: flex;
}

.wotd {
    margin: auto;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 30vw;
    padding: 2rem;
    color: var(--primary);
    /* border: 0.1rem solid var(--primary); */
    border-radius: 0.5rem;
    line-height: 2;
    text-align: center;
}

.wotd-title {
    font-size: 1.5vw;
}

.wotd-hr {
    margin: 0.5vh 0;
}

.wotd-content {
    /* line-height: 1.5; */
    font-size: 1vw;
}

.wotd-word {
    font-size: 1.5vw;
    color: var(--classi2);
}

.wotd-info {
    /* padding: 0 0 1rem 1rem; */
    font-size: 0.8vw;
    color: var(--accent4);
}

.wotd-meaning {
    font-size: 1vw;
}

.slide-out-leave-active,
.slide-in-enter-active {
    transition: all ease-out 0.5s;
}

.slide-in-enter-from,
.slide-out-leave-to {
    transform: translateY(-30vh);
    opacity: 0;
}

.content {
    display: flex;
    /* flex-wrap: wrap; */
    margin-top: 5vh;
}

.column1 {
    display: flex;
    flex-direction: column;
    width: 30vw;
    height: 80vh;
    margin: auto;
}

.column2 {
    display: flex;
    flex-direction: column;
    width: 40vw;
    height: 75vh;
    margin: auto;
}

.completed-assignments {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    padding-top: 5vh;
}

.donut-chart {
    margin: 2rem auto;
    height: 37vh;
    width: 25vw;
}

.notice-board {
    margin: auto;
    margin-top: 1vh;
    height: 75vh;
    width: 35vw;
    /* padding: 2vh 2vw; */
    background-color: var(--accent3);
    color: var(--primary);
    border-radius: 1rem;
    overflow: hidden;
}

.notice-board-title {
    background-color: var(--classi2);
    color: white;
    font-size: 1.7vw;
    text-align: center;
    padding: 1.5vh 2vw;
    margin-bottom: 8vh;
    /* border-bottom: 0.1rem solid var(--primary); */
}

.notice-item {
    list-style: none;
    margin-bottom: 3vh;
    padding: 1vh 3vw;
    /* border-bottom: 0.05rem dashed #ddd; */
    transition: all ease 0.3s;
}

.notice-item:hover {
    transform: translateY(-0.5vh);
}

.pin {
    margin-right: 0.2vw;
    /* rotate: 270deg; */
}

.notice-title {
    display: flex;
    align-items: center;
    font-size: 1vw;
}

.notice-item-content {
    margin: 1vh 1.6vw;
    color: var(--accent2);
    font-size: 0.8vw;
}

.notice-item small {
    display: block;
    text-align: right;
    color: #999;
}
</style>
