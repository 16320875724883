<template>
  <div class="app-container">
    <Sidebar />
    <div class="content-container">
      <StudentHeader />
      <div class="content">
        <h2>Communication Tools</h2>
        
        <!-- Mass Email Section -->
        <div class="comm-section">
          <h3>Mass Email</h3>
          <p>Send emails to multiple students or parents at once.</p>
          <button @click="composeEmail" class="primary-button">Compose Mass Email</button>
          <h4>Recent Emails</h4>
          <div v-for="(email, index) in recentEmails" :key="index" class="email-item">
            <strong>Subject:</strong> {{ email.subject }} <br>
            Sent: {{ email.sentDate }}
          </div>
        </div>

        <!-- Announcements Section -->
        <div class="comm-section">
          <h3>Announcements</h3>
          <p>Post announcements for students and parents.</p>
          <button @click="createAnnouncement" class="primary-button">Create Announcement</button>
          <h4>Recent Announcements</h4>
          <div v-for="(announcement, index) in recentAnnouncements" :key="index" class="announcement-item">
            <strong>{{ announcement.title }}</strong> <br>
            Posted: {{ announcement.postedDate }}
          </div>
        </div>

        <!-- Parent-Teacher Conference Section -->
        <div class="comm-section">
          <h3>Parent-Teacher Conference</h3>
          <p>Schedule and manage parent-teacher conferences.</p>
          <button @click="scheduleConference" class="primary-button">Schedule Conferences</button>
          <h4>Upcoming Conferences</h4>
          <div v-for="(conference, index) in upcomingConferences" :key="index" class="conference-item">
            <strong>{{ conference.family }}</strong> <br>
            Scheduled: {{ conference.date }}
          </div>
        </div>

        <!-- Chat Support Section -->
        <div class="comm-section">
          <h3>Chat Support</h3>
          <p>Instant messaging for quick communication with students or parents.</p>
          <button @click="openChatConsole" class="primary-button">Open Chat Console</button>
          <h4>Recent Chats</h4>
          <div v-for="(chat, index) in recentChats" :key="index" class="chat-item">
            <strong>{{ chat.name }}</strong> <br>
            Last message: {{ chat.lastMessageDate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/TeacherSidebar.vue";

export default {
  name: "TeacherCommunication",
  components: {
    StudentHeader,
    Sidebar,
  },
  data() {
    return {
      recentEmails: [
        { subject: "Upcoming Field Trip", sentDate: "Jun 15, 2024" },
        { subject: "End of Year Celebration", sentDate: "Jun 10, 2024" },
      ],
      recentAnnouncements: [
        { title: "Summer Reading List Posted", postedDate: "Jun 18, 2024" },
        { title: "Final Exam Schedule", postedDate: "Jun 5, 2024" },
      ],
      upcomingConferences: [
        { family: "Smith Family", date: "Jun 25, 2024, 3:00 PM" },
        { family: "Johnson Family", date: "Jun 26, 2024, 4:30 PM" },
      ],
      recentChats: [
        { name: "Sarah L. (Student)", lastMessageDate: "Jun 20, 2024" },
        { name: "Mr. Brown (Parent)", lastMessageDate: "Jun 19, 2024" },
      ],
    };
  },
  methods: {
    composeEmail() {
      alert("Compose Mass Email clicked");
    },
    createAnnouncement() {
      alert("Create Announcement clicked");
    },
    scheduleConference() {
      alert("Schedule Conferences clicked");
    },
    openChatConsole() {
      alert("Open Chat Console clicked");
    },
  },
};
</script>

<style scoped>
.app-container {
  display: flex;
}

.content-container {
  flex: 1;
  margin-left: 250px; /* Ensure this matches the width of the sidebar */
  display: flex;
  flex-direction: column;
}

.content {
  padding: 20px;
  background-color: var(--background-color);
}

.comm-section {
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.primary-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #0033cc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.primary-button:hover {
  background-color: #002299;
}

.email-item, .announcement-item, .conference-item, .chat-item {
  margin-top: 10px;
  padding: 10px;
  background-color: var(--secondary);
  border-radius: 5px;
}

h3 {
  margin-top: 0;
}
</style>
