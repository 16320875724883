<template>
    <div class="chart-container">
        <Doughnut :data="chartData" :options="chartOptions" />
        <div class="center-text">
            <p class="total">Assignments Status</p>
            <p class="total">Total: {{ displayedTotal }}</p>
        </div>
    </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
    name: "App",
    components: {
        Doughnut,
    },
    data() {
        return {
            displayedTotal: 0,
        };
    },
    mounted() {
        this.animateAssignmentsTotal();
    },
    watch: {
        displayedTotal() {
            this.animateAssignmentsTotal();
        },
    },
    props: {
        assignmentsData: {
            type: Array,
            required: true,
        },
        pieBorderColor: {
            type: String,
            required: true,
        },
    },
    methods: {
        handleSegmentClick(event, elements) {
            elements[0].element.$context.raw.onClick();
        },
        delay(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        async animateAssignmentsTotal() {
            const count = this.assignmentsData[0].total;
            if (this.displayedTotal === count) return;
            if (this.displayedTotal < count) {
                await this.delay(20);
                this.displayedTotal++;
            }
        },
    },
    computed: {
        chartData() {
            return {
                labels: this.assignmentsData.map((item) => item.label),
                datasets: [
                    {
                        label: "Assignments Completed",
                        data: this.assignmentsData,
                        backgroundColor: this.assignmentsData.map(
                            (item) => item.color
                        ),
                        // hoverBackgroundColor: this.assignmentsData.map(
                        //     (item) => item.hoverColor
                        // ),
                    },
                ],
            };
        },
        chartOptions() {
            return {
                animation: { animateScale: true, animateRotate: true },
                cutout: "70%",
                borderColor: this.pieBorderColor,
                hoverBorderColor: this.pieBorderColor,
                hoverBorderWidth: 10,
                responsive: true,
                maintainAspectRatio: false,
                onClick: this.handleSegmentClick,
                onHover: (event, chartElement) => {
                    event.native.target.style.cursor = chartElement[0]
                        ? "pointer"
                        : "default";
                },
                plugins: {
                    legend: {
                        position: "none",
                    },
                    tooltip: {
                        displayColors: false,
                        yAlign: "bottom",
                        callbacks: {
                            label: function (tooltipItem) {
                                const data = tooltipItem.raw;
                                const total = data.total;
                                const completed = data.value;
                                const percentage = (
                                    (completed / total) *
                                    100
                                ).toFixed(2);
                                return `${completed} (${percentage}%)`;
                            },
                        },
                    },
                    title: {
                        display: false,
                        text: "Assignments Completed by Subject",
                    },
                },
            };
        },
    },
};
</script>

<style scoped>
.chart-container {
    width: 100%;
    height: 100%;
}
.center-text {
    margin: auto;
    width: fit-content;
    position: relative;
    font-size: 1.5rem;
    text-align: center;
    bottom: 57%;
    color: var(--primary);
}
.total {
    color: var(--accent4);
    font-size: 1rem;
}
</style>
