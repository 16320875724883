<template>
    <div class="header">
        <div class="logo" @click="goHome">
            <span class="classi1">cl</span>
            <span class="classi2">a</span>
            <span class="classi1">ss</span>
            <span class="classi2">i</span>
        </div>
        <div class="themeDiv">
            <ThemeSwitcher />
        </div>
    </div>
</template>

<script>
import ThemeSwitcher from "./ThemeSwitcher.vue";

export default {
    name: "Header",
    components: {
        ThemeSwitcher,
    },
    methods: {
        goHome() {
            this.$router.push("/");
        },
    },
};
</script>

<style scoped>
.header {
    display: flex;
    padding-bottom: 6vh;
}

.logo {
    cursor: pointer;
    flex: 1;
    text-align: left;
    margin-top: 0;
    font-size: 5vh;
    font-weight: 100;
    letter-spacing: 1vh;
    font-family: var(--accent-font);
    /* color:; */
}

.homeDiv {
    flex: 1;
    text-align: center;
}

.themeDiv {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.classi1 {
    color: var(--classi1);
}

.classi2 {
    color: var(--classi2);
}

button {
    background-color: transparent;
    border: none;
}

.home-icon {
    color: var(--primary);
    font-size: 2vw;
}

.home-icon:hover {
    color: darkgray;
}
</style>
