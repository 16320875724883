<template>
  <div class="app-container">
    <Sidebar />
    <div class="content-container">
      <StudentHeader />
      <div class="content">
        <h2>Professional Development</h2>

        <!-- Training Courses Section -->
        <div class="pd-section">
          <h3>Training Courses</h3>
          <p>Access and enroll in professional development courses.</p>
          <div class="course-progress" v-for="(course, index) in trainingCourses" :key="index">
            <strong>{{ course.name }}</strong>
            <div class="progress-bar">
              <div class="progress" :style="{ width: course.progress + '%' }"></div>
            </div>
          </div>
          <button @click="browseCourses" class="primary-button">Browse All Courses</button>
        </div>

        <!-- Certifications Section -->
        <div class="pd-section">
          <h3>Certifications</h3>
          <p>View and pursue additional teaching certifications.</p>
          <div class="certification-item" v-for="(certification, index) in certifications" :key="index">
            {{ certification }}
          </div>
          <button @click="exploreCertifications" class="primary-button">Explore Certifications</button>
        </div>

        <!-- Workshops and Webinars Section -->
        <div class="pd-section">
          <h3>Workshops and Webinars</h3>
          <p>Participate in upcoming professional development events.</p>
          <div class="workshop-item" v-for="(workshop, index) in workshops" :key="index">
            <strong>{{ workshop.title }}</strong> <br>
            Date: {{ workshop.date }}
          </div>
          <button @click="registerEvents" class="primary-button">Register for Events</button>
        </div>

        <!-- Resource Library Section -->
        <div class="pd-section">
          <h3>Resource Library</h3>
          <p>Access teaching resources and best practice guides.</p>
          <button @click="browseResources" class="primary-button">Browse Resources</button>
          <button @click="submitResource" class="primary-button">Submit Resource</button>
        </div>

        <!-- Professional Goals Section -->
        <div class="pd-section">
          <h3>Professional Goals</h3>
          <p>Set and track your professional development goals.</p>
          <button @click="viewGoals" class="primary-button">View My Goals</button>
          <button @click="setNewGoal" class="primary-button">Set New Goal</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/TeacherSidebar.vue";

export default {
  name: "TeacherProfessionalDevelopment",
  components: {
    StudentHeader,
    Sidebar,
  },
  data() {
    return {
      trainingCourses: [
        { name: "Advanced Classroom Management", progress: 80 },
        { name: "Technology in Education", progress: 60 },
      ],
      certifications: [
        "Special Education Certification",
        "TESOL Certification",
      ],
      workshops: [
        { title: "Effective Online Teaching Strategies", date: "July 15, 2024" },
        { title: "Inclusive Classroom Practices", date: "August 5, 2024" },
      ],
    };
  },
  methods: {
    browseCourses() {
      alert("Browse All Courses clicked");
    },
    exploreCertifications() {
      alert("Explore Certifications clicked");
    },
    registerEvents() {
      alert("Register for Events clicked");
    },
    browseResources() {
      alert("Browse Resources clicked");
    },
    submitResource() {
      alert("Submit Resource clicked");
    },
    viewGoals() {
      alert("View My Goals clicked");
    },
    setNewGoal() {
      alert("Set New Goal clicked");
    },
  },
};
</script>

<style scoped>
.app-container {
  display: flex;
}

.content-container {
  flex: 1;
  margin-left: 250px; /* Ensure this matches the width of the sidebar */
  display: flex;
  flex-direction: column;
}

.content {
  padding: 20px;
  background-color: var(--background-color);
}

.pd-section {
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.primary-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #0033cc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.primary-button:hover {
  background-color: #002299;
}

.course-progress {
  margin-bottom: 10px;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 10px;
  background-color: green;
}

.certification-item, .workshop-item {
  background-color: var(--secondary);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}
</style>
