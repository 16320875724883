import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import TextbookChat from "@/views/Student/TextbookChat.vue";
import TeachersPage from "@/views/Teacher/TeachersPage.vue";
import TeachersPage2 from "@/views/Teacher/TeachersPage2.vue";
import Grader from "@/views/Grader.vue";
import GraderDemo from "@/views/GraderDemo.vue";
import StudentDashboard from "@/views/Student/StudentDashboard.vue";
import StudentClasses from "@/views/Student/StudentClasses.vue";
import StudentSchedule from "@/views/Student/StudentSchedule";
import StudentGrades from "@/views/Student/StudentGrades";
import StudentAdmin from "@/views/Student/StudentAdmin";
import TeacherAdmin from "@/views/Teacher/TeacherAdmin";
import TeacherAITools from "@/views/Teacher/TeacherAITools";
import TeacherSchedule from "@/views/Teacher/TeacherSchedule";
import TeacherManagement from "@/views/Teacher/TeacherManagement";
import TeacherCommunication from "@/views/Teacher/TeacherCommunication";
import TeacherProfDev from "@/views/Teacher/TeacherProfDev";
import StudentInterests from "@/views/Student/StudentInterests";
import LoginPage from "@/views/LoginPage.vue";
import { useStudentStore } from "@/store/studentData";
import StudentProfile from "@/views/Student/StudentProfile.vue";
import StudentAssignments from "@/views/Student/StudentAssignments.vue";
import StudentCalendar from "@/views/Student/StudentCalendar.vue";
import StudentTimetable from "@/views/Student/StudentTimetable.vue";
import StudentCourse from "@/components/StudentCourse.vue";
import StudentAssignment from "@/components/StudentAssignment.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/login",
        name: "Login Page",
        component: LoginPage,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: StudentDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/classes",
        name: "StudentClasses",
        component: StudentClasses,
        meta: { requiresAuth: true },
        children: [
            {
                path: ":courseCode",
                name: "StudentCourse",
                component: StudentCourse,
                props: (route) => ({
                    courseCode: route.params.courseCode,
                }),
            },
        ],
    },
    {
        path: "/assignments",
        name: "StudentAssignments",
        component: StudentAssignments,
        meta: { requiresAuth: true },
        props: (route) => ({
            selectedFilters: route.query.selectedFilters,
        }),
        children: [
            {
                path: ":assignmentId",
                name: "StudentAssignment",
                component: StudentAssignment,
                props: (route) => ({
                    assignmentId: route.params.assignmentId,
                }),
            },
        ],
    },
    {
        path: "/schedule",
        name: "Schedule",
        component: StudentSchedule,
        meta: { requiresAuth: true },
    },
    {
        path: "/calendar",
        name: "Calendar",
        component: StudentCalendar,
        meta: { requiresAuth: true },
    },
    {
        path: "/grades",
        name: "Grades",
        component: StudentGrades,
        meta: { requiresAuth: true },
    },
    {
        path: "/timetable",
        name: "Timetable",
        component: StudentTimetable,
        meta: { requiresAuth: true },
    },
    {
        path: "/teachers",
        name: "Teachers Page",
        component: TeachersPage,
    },
    {
        path: "/profile",
        name: "StudentProfile",
        component: StudentProfile,
        meta: { requiresAuth: true },
    },

    {
        path: "/teachers2",
        name: "Teachers Page 2",
        component: TeachersPage2,
    },
    {
        path: "/chatbot",
        name: "AURA Chatbot",
        component: TextbookChat,
    },
    {
        path: "/grader",
        name: "GraderDemo",
        component: GraderDemo,
    },
    {
        path: "/studentInterests",
        name: "StudentInterests",
        component: StudentInterests,
    },
    {
        path: "/teacherSchedule",
        name: "TeacherSchedule",
        component: TeacherSchedule,
    },
    {
        path: "/admin",
        name: "Admin",
        component: StudentAdmin,
    },
    {
        path: "/teacherAdmin",
        name: "TeacherAdmin",
        component: TeacherAdmin,
    },
    {
        path: "/AITools",
        name: "AITools",
        component: TeacherAITools,
    },
    {
        path: "/management",
        name: "TeacherClassManagement",
        component: TeacherManagement,
    },
    {
        path: "/communication",
        name: "TeacherCommunication",
        component: TeacherCommunication,
    },
    {
        path: "/profDev",
        name: "TeacherProfDev",
        component: TeacherProfDev,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Route guard to check authentication
router.beforeEach(async (to, from, next) => {
    const studentStore = useStudentStore();
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (requiresAuth) {
        if (!studentStore.isAuthenticated) {
            try {
                await studentStore.checkAuth();
                if (studentStore.isAuthenticated) {
                    next();
                } else {
                    next("/login");
                }
            } catch (error) {
                next("/login");
            }
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
