<template>
    <div class="submissionContainer">
        <p>Upload an answer sheet in pdf format for grading:</p><br /> <br />
        <input class="fileInput" type="file" @change="onFileChange" accept="application/pdf" />
        <div v-if="loading" class="spinner">Loading...</div>
        <embed v-if="pdfUrl && !loading" :src="pdfUrl" type="application/pdf" />
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            pdfUrl: null,
            submissionId: null,
            loading: false,
        };
    },
    methods: {
        onFileChange(event) {
            const file = event.target.files[0];
            if (file && file.type === "application/pdf") {
                this.loading = true;
                const formData = new FormData();
                formData.append('file', file);

                axios.post('http://127.0.0.1:5000/grader/encode-images', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        console.log('Response:', response.data);
                        this.submissionId = response.data.submissionId;
                        this.pdfUrl = URL.createObjectURL(file);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });

                this.$emit('file-uploaded', this.submissionId);
            }
        },
    },
    beforeDestroy() {
        // Revoke the object URL to avoid memory leaks
        if (this.pdfUrl) {
            URL.revokeObjectURL(this.pdfUrl);
        }
    },
};
</script>

<style scoped>
.submissionContainer {
    padding: 5vh;
    text-align: center;
    color: var(--primary);

}

embed {
    width: 100%;
    height: 45vh;
    margin-top: 5vh;
}

.spinner {
    margin-top: 20px;
    font-size: 1.5em;
    color: var(--primary);
}

.fileInput {
    cursor: pointer;
}
</style>
