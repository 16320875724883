<template>
    <div class="spinner"></div>
</template>

<script>
export default {
    name: "LoadingSpinner",
};
</script>

<style scoped>
.spinner {
    border: 0.5rem solid var(--accent4);
    border-top: 0.5rem solid var(--classi2);
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
