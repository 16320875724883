<template>
    <div>
        <Header />
        <div class="teachersPageContainer">
            <button class="box" @click="goToGrader">Auto Grader</button>
            <button class="box" @click="">Lecture Summarization</button>
            <button class="box" @click="">Lecture Planning</button>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"
import ChatBox from "@/components/ChatBox.vue";

export default {
    name: "Teachers Page",
    components: {
        Header,
    },
    methods: {
        goToGrader() {
            this.$router.push("/grader")
        }
    }
};
</script>

<style scoped>
.teachersPageContainer {
    display: grid;
    margin: auto;
    width: 80vw;
    height: 60vh;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    row-gap: 5vh;
    column-gap: 0px;
}

button {
    margin: auto;
    height: 30vh;
    width: 25vw;
    border: 3px double var(--primary);
    background-color: transparent;
    color: var(--primary);
    font-size: 1.5vh;
    font-weight: 500;
    letter-spacing: 0.2vh;
}

button:hover {
    background-color: var(--primary);
    border: none;
    color: var(--secondary);
    transition: background-color 0.3s ease;
    cursor: pointer;
}
</style>