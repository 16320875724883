<template>
  <div class="app-container">
    <Sidebar />
    <div class="content-container">
      <StudentHeader />
      <div class="content">
        <h2>Class Management</h2>
        <div v-for="(classItem, index) in classes" :key="index" class="class-card">
          <div class="class-header">
            <h3>{{ classItem.name }}</h3>
            <div class="class-actions">
              <button @click="editClass(index)" class="edit-button">Edit</button>
              <button @click="deleteClass(index)" class="delete-button">Delete</button>
            </div>
          </div>
          <div class="assignments">
            <h4>Upcoming Assignments</h4>
            <div v-for="(assignment, aIndex) in classItem.assignments" :key="aIndex" class="assignment">
              <strong>{{ assignment.type }}:</strong> {{ assignment.name }} <br>
              Due: {{ assignment.due }}
            </div>
            <div class="assignment-actions">
              <button @click="generateWorksheet(classItem.name)">Generate Worksheet</button>
              <button @click="createQuiz(classItem.name)">Create Quiz</button>
            </div>
          </div>
        </div>
        <button class="add-class-button" @click="addClass">Add New Class</button>
      </div>
    </div>
  </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/TeacherSidebar.vue";

export default {
  name: "TeacherClassManagement",
  components: {
    StudentHeader,
    Sidebar,
  },
  data() {
    return {
      classes: [
        {
          name: "Mathematics 101",
          assignments: [
            { type: "Problem Set", name: "Problem Set 3", due: "Jun 25, 2024" },
            { type: "Quiz", name: "Algebra Basics", due: "Jun 28, 2024" },
          ],
        },
        {
          name: "History 202",
          assignments: [
            { type: "Essay", name: "Industrial Revolution", due: "Jun 30, 2024" },
            { type: "Test", name: "World War II", due: "Jul 5, 2024" },
          ],
        },
      ],
    };
  },
  methods: {
    editClass(index) {
      alert(`Edit class ${this.classes[index].name}`);
    },
    deleteClass(index) {
      if (confirm(`Are you sure you want to delete ${this.classes[index].name}?`)) {
        this.classes.splice(index, 1);
      }
    },
    addClass() {
      alert("Add new class clicked");
    },
    generateWorksheet(className) {
      alert(`Generate worksheet for ${className}`);
    },
    createQuiz(className) {
      alert(`Create quiz for ${className}`);
    },
  },
};
</script>

<style scoped>
.app-container {
  display: flex;
}

.content-container {
  flex: 1;
  margin-left: 250px; /* Ensure this matches the width of the sidebar */
  display: flex;
  flex-direction: column;
}

.content {
  padding: 20px;
  background-color: var(--background-color);
}

.class-card {
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.class-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.class-actions {
  display: flex;
  gap: 10px;
}

.edit-button, .delete-button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.edit-button {
  background-color: #007bff;
  color: white;
}

.edit-button:hover {
  background-color: #0056b3;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.delete-button:hover {
  background-color: #c82333;
}

.assignments {
  margin-top: 20px;
}

.assignment {
  margin-bottom: 10px;
}

.assignment-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.assignment-actions button {
  padding: 5px 10px;
  background-color: var(--background-color);
  border: 1px solid var(--primary);
  border-radius: 5px;
  cursor: pointer;
}

.assignment-actions button:hover {
  background-color: var(--hover-background);
}

.add-class-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-class-button:hover {
  background-color: #218838;
}
</style>
