<template>
    <div class="typewriter">
        <span v-if="!classi">{{ displayedText }}</span>
        <Transition>
            <div v-if="classi && classiAppear" class="classi">
                <span class="classi1">cl</span>
                <span class="classi2">a</span>
                <span class="classi1">ss</span>
                <span class="classi2">i</span>
                <span class="classi1">.</span>
            </div>
        </Transition>
        <!-- <span v-if="isTyping" class="cursor">|</span> -->
    </div>
</template>

<script>
export default {
    name: "Typewriter",
    props: {
        text: {
            type: String,
            required: true,
        },
        speed: {
            type: Number,
            default: 100,
        },
        classi: {
            type: Boolean,
            default: false,
        },
        offset: {
            type: Number,
            default: 0, // Default offset is 0 milliseconds
        },
    },
    data() {
        return {
            displayedText: "",
            isTyping: false,
            classiAppear: false,
        };
    },
    async mounted() {
        await this.delay(this.offset); // Wait for the offset before starting the typing effect
        this.startTyping();
    },
    methods: {
        async startTyping() {
            this.isTyping = true;
            for (let i = 0; i < this.text.length; i++) {
                this.displayedText += this.text.charAt(i);
                await this.delay(this.speed);
            }
            this.isTyping = false;
            this.classiAppear = true;
            await this.delay(2000);
            this.$emit("typing-complete");
        },
        delay(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
    },
};
</script>
<style scoped>
.typewriter {
    font-family: var(--accent-font);
    font-size: 7rem;
    white-space: pre;
}

.cursor {
    display: inline-block;
    width: 0.01rem;
    background-color: var(--primary);
    animation: blink 0.7s steps(1) infinite;
}

.classi {
    flex: 1;
    display: flex;
    text-align: center;
    justify-content: center;
}

.classi1 {
    color: var(--classi1);
}

.classi2 {
    color: var(--classi2);
}

.v-enter-active,
.v-leave-active {
    transition: opacity 2s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
