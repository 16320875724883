<template>
    <div :class="['main-container', { collapsed: isCollapsed }]">
        <Sidebar @toggle="handleToggle" :collapsed="isCollapsed" />
        <div :class="['content-container', { collapsed: isCollapsed }]">
            <StudentHeader title="AURA" />
            <LoadingSpinner class="spinner" v-if="loading" />
            <div :class="['content', { noshow: loading }]">
                <ChatBox @textbook-selected="handleTextbookSelected" />
            </div>
        </div>
    </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import ChatBox from "@/components/ChatBox.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
    name: "Textbook Chat",
    components: {
        StudentHeader,
        Sidebar,
        ChatBox,
        LoadingSpinner,
    },
    data() {
        return {
            isCollapsed: false,
            loading: true,
        };
    },
    methods: {
        handleToggle(collapsed) {
            this.isCollapsed = !collapsed;
        },
        handleTextbookSelected() {
            this.loading = false;
        },
    },
};
</script>

<style scoped>
.main-container {
    display: flex;
    transition: all 0.3s ease-in-out;
    font-family: var(--main-font);
}

.main-container.collapsed .content-container {
    margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
    margin-left: 18vw;
}

.content-container {
    flex: 1;
    transition: margin-left 0.3s ease-in-out;
    color: var(--primary);
}

.spinner {
    margin: 20vh auto;
}

.content.noshow {
    display: none;
}
</style>
