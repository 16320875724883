<template>
    <div class="filter-menu" ref="filterMenu">
        <div class="filter-buttons">
            <div class="filters-title">
                Filters
                <span class="material-symbols-outlined filter-icon">
                    filter_list
                </span>
            </div>

            <div class="filter-group">
                <button @click="toggleCompletionFilter" class="filter-button">
                    Status
                </button>
                <div v-if="showCompletionFilter" class="filter-content">
                    <div class="filter-section">
                        <label>
                            <input
                                type="checkbox"
                                v-model="filters.completion.graded"
                            />
                            Graded
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                v-model="filters.completion.submitted"
                            />
                            Submitted
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                v-model="filters.completion.inProgress"
                            />
                            In Progress
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                v-model="filters.completion.incomplete"
                            />
                            Incomplete
                        </label>
                    </div>
                    <button @click="clearCompletionFilter" class="clear-button">
                        Clear
                    </button>
                </div>
            </div>

            <div class="filter-group">
                <button @click="toggleSubjectFilter" class="filter-button">
                    Subjects
                </button>
                <div v-if="showSubjectFilter" class="filter-content">
                    <div class="filter-section">
                        <div v-for="subject in subjects" :key="subject">
                            <label>
                                <input
                                    type="checkbox"
                                    :value="subject"
                                    v-model="filters.subjects"
                                />
                                {{ subject }}
                            </label>
                        </div>
                    </div>
                    <button @click="clearSubjectFilter" class="clear-button">
                        Clear
                    </button>
                </div>
            </div>

            <div class="filter-group">
                <button @click="toggleDueDateFilter" class="filter-button">
                    Due Date
                </button>
                <div v-if="showDueDateFilter" class="filter-content">
                    <div class="filter-section">
                        <label>
                            <input
                                type="checkbox"
                                v-model="filters.dueDate.today"
                            />
                            Today
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                v-model="filters.dueDate.thisWeek"
                            />
                            Within A Week
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                v-model="filters.dueDate.thisMonth"
                            />
                            Within A Month
                        </label>
                    </div>
                    <button @click="clearDueDateFilter" class="clear-button">
                        Clear
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        filters: Object,
        subjects: Array,
    },
    data() {
        return {
            showCompletionFilter: false,
            showSubjectFilter: false,
            showDueDateFilter: false,
        };
    },
    methods: {
        toggleCompletionFilter() {
            this.showCompletionFilter = !this.showCompletionFilter;
            this.showSubjectFilter = false;
            this.showDueDateFilter = false;
        },
        toggleSubjectFilter() {
            this.showSubjectFilter = !this.showSubjectFilter;
            this.showCompletionFilter = false;
            this.showDueDateFilter = false;
        },
        toggleDueDateFilter() {
            this.showDueDateFilter = !this.showDueDateFilter;
            this.showCompletionFilter = false;
            this.showSubjectFilter = false;
        },
        clearCompletionFilter() {
            this.filters.completion.graded = false;
            this.filters.completion.submitted = false;
            this.filters.completion.inProgress = false;
            this.filters.completion.incomplete = false;
        },
        clearSubjectFilter() {
            this.filters.subjects = [];
        },
        clearDueDateFilter() {
            this.filters.dueDate.today = false;
            this.filters.dueDate.thisWeek = false;
            this.filters.dueDate.thisMonth = false;
        },
        closeAllFilters() {
            this.showCompletionFilter = false;
            this.showSubjectFilter = false;
            this.showDueDateFilter = false;
        },
        handleClickOutside(event) {
            if (
                this.$refs.filterMenu &&
                !this.$refs.filterMenu.contains(event.target)
            ) {
                this.closeAllFilters();
            }
        },
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },
};
</script>

<style scoped>
.filter-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filter-buttons {
    display: flex;
    gap: 1vw;
}

.filter-group {
    position: relative;
}

.filter-button {
    background-color: var(--primary);
    color: var(--secondary);
    height: 4vh;
    width: 8vw;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8vw;
    transition: all ease 0.3s;
}

.filter-button:hover {
    opacity: 0.6;
}

.filters-title {
    line-height: 2;
    font-size: 1vw;
}

.filter-icon {
    font-size: 1.2vw;
    margin-left: 0.5vw;
    vertical-align: middle;
}

.filter-content {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--accent3);
    padding: 1rem;
    margin-top: 0.5vh;
    border: 0.1rem solid var(--primary);
    width: 10vw;
    border-radius: 1rem;
    box-shadow: var(--box-shadow);
    z-index: 1;
    font-size: 0.8vw;
}

.filter-section {
    margin-bottom: 1rem;
    line-height: 2;
}

.filter-section label {
    display: block;
    margin-bottom: 5px;
}

.clear-button {
    background-color: var(--classi2);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 14px;
    transition: all ease 0.3s;
}

.clear-button:hover {
    opacity: 0.6;
}
</style>
