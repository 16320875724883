<template>
    <header class="header">
        <!-- <h1 v-if="!loading" class="welcome">
            Welcome, {{ studentStore.firstName }}
        </h1> -->
        <p class="header-title">{{ title }}</p>
        <div class="icons">
            <div class="notifications" @click="toggleNotificationsMenu">
                <span class="material-symbols-outlined"> notifications </span>
                <div
                    v-if="isNotificationsMenuVisible"
                    class="notifications-bubble"
                >
                    <h2>Coming soon</h2>
                </div>
            </div>
            <div class="profile-pic" @click="toggleProfileMenu">
                <img :src="userAvatar" class="profile-pic" />
                <div
                    v-if="isProfileMenuVisible"
                    class="menu-bubble"
                    @click.stop
                >
                    <ul>
                        <li @click="goToProfile">Profile</li>
                        <li @click="logout">Logout</li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { useStudentStore } from "@/store/studentData";

export default {
    data() {
        return {
            isNotificationsMenuVisible: false,
            isProfileMenuVisible: false,
            loading: true,
            userAvatar: require("@/assets/JohnSnow.jpg"),
        };
    },
    props: {
        title: "",
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
    },
    async mounted() {
        if (this.studentStore.studentId === -1) {
            try {
                await this.studentStore.retrieveStudentDataFromBackend();
            } catch (error) {
                console.error("Failed to fetch student data:", error);
            } finally {
                this.loading = false;
            }
        } else {
            this.loading = false;
        }
        document.addEventListener("click", this.handleOutsideClick);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.handleOutsideClick);
    },
    methods: {
        toggleNotificationsMenu() {
            // this.isNotificationsMenuVisible = !this.isNotificationsMenuVisible;
            alert("Coming soon");
        },
        toggleProfileMenu() {
            this.isProfileMenuVisible = !this.isProfileMenuVisible;
            console.log(this.studentStore.email);
        },
        async logout() {
            await this.studentStore.logout();
            this.$router.push("/login");
        },
        goToProfile() {
            this.$router.push("/profile");
        },
        handleOutsideClick(event) {
            if (!this.$el.contains(event.target)) {
                this.isProfileMenuVisible = false;
            }
        },
    },
};
</script>

<style scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--primary);
    margin-top: 1.3vh;
    margin-bottom: 1.5vh;
    font-family: var(--accent-font);
    font-size: 2.3vw;
}

.welcome {
    font-size: 1.5rem;
    color: var(--text-primary);
}

.icons {
    display: flex;
    justify-content: space-between;
}

.notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 2rem;
    height: 2.5vw;
    width: 2.5vw;
    margin-right: 1vw;
    border: 0.2rem solid var(--primary);
    border-radius: 50%;
}

.notifications:hover {
    background-color: var(--primary);
    color: var(--secondary);
    transition: all ease 0.3s;
}

.notifications-bubble {
    position: fixed;
    top: 10vh;
    right: 5vw;
    height: 20vh;
    width: 8vw;
    background-color: var(--accent3);
    box-shadow: var(--box-shadow);
    color: var(--primary);
    border-radius: 1rem;
    font-size: 1rem;
    font-family: var(--main-font);
    padding: 1rem;
    z-index: 2;
}

.profile-pic {
    width: 2.5vw;
    height: 2.5vw;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
}

.menu-bubble {
    position: relative;
    top: 0vh;
    right: 5vw;
    width: 8vw;
    background-color: var(--accent3);
    box-shadow: var(--box-shadow);
    color: var(--primary);
    border: 1px solid var(--accent2);
    border-radius: 0.5rem;
    font-size: 0.7vw;
    font-family: var(--main-font);
    padding: 1vh 1vw;
    z-index: 2;
}

.menu-bubble ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-bubble li {
    padding: 1vh 1vw;
    cursor: pointer;
    border-radius: 0.5rem;
}

.menu-bubble li:hover {
    background-color: var(--secondary);
    color: var(--primary);
}
</style>
